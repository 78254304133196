import React from 'react';
import {
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import {
  User,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { UserFormValues } from '../../types/forms';
import { isValidPhoneNumber } from '../../utils/forms';
import { formatRut, isValidRut } from '../../utils/rut';
import { RutInput } from '../../components/RutInput';

const { Text } = Typography;

export interface UserFormProps {
  form: FormInstance<UserFormValues>;
  user?: User | null;
  editing?: boolean;
  setEditing?: (saving: boolean) => void;
  setSaving: (saving: boolean) => void;
  onComplete: (owner: User) => void;
}

interface UserResponseData {
  rut: string[];
  email: string[];
}

export default function OwnerForm({
  form, user = null, editing = true, setEditing = () => {}, setSaving, onComplete,
}: UserFormProps) {
  const onCreate = (values: UserFormValues) => {
    setSaving(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/users/`,
      {
        ...values,
        groups: ['owners'],
        rut: values.rut?.replaceAll('.', ''),
      },
    ).then((response) => {
      setSaving(false);
      onComplete(response.data);
    }).catch((e) => {
      const errorMessages = (e.response?.data as UserResponseData);
      if ((errorMessages.email || []).join(', ').includes('user with this email already exists')) {
        message.error('El correo ingresado ya está registrado');
      } else if ((errorMessages.rut || []).join(', ').includes('user with this rut already exists')) {
        message.error('El RUT ingresado ya está registrado');
      } else {
        message.error('Ocurrió un error al agregar a la persona, por favor intenta nuevamente.');
      }
      setSaving(false);
    });
  };

  const onUpdate = (values: UserFormValues) => {
    if (user) {
      setSaving(true);
      axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/users/${user.id}/`,
        {
          ...values,
          id: user.id,
          rut: values.rut?.replaceAll('.', ''),
        },
      ).then((response) => {
        setSaving(false);
        if (setEditing) {
          setEditing(false);
        }
        onComplete(response.data);
      }).catch((e) => {
        const errorMessages = (e.response?.data as UserResponseData);
        if ((errorMessages.email || []).join(', ').includes('user with this email already exists')) {
          message.error('El correo ingresado ya está registrado');
        } else if ((errorMessages.rut || []).join(', ').includes('user with this rut already exists')) {
          message.error('El RUT ingresado ya está registrado');
        } else {
          message.error('Ocurrió un error al agregar a la persona, por favor intenta nuevamente.');
        }
        setSaving(false);
      });
    }
  };

  return (
    <Form
      form={form}
      initialValues={user ? {
        ...user,
        rut: user.rut ? formatRut(user.rut) : null,
      } : {}}
      onFinish={user ? onUpdate : onCreate}
      autoComplete="off"
      size="small"
    >
      <div style={{ maxWidth: 500, paddingTop: 20, paddingBottom: 20 }}>
        <Row gutter={[8, 32]} align="middle">
          <Col span={10}>
            <Text strong>Nombre:</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="first_name"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa un nombre',
                },
              ]}
            >
              {editing ? (
                <Input placeholder="Ingresa un apellido" />
              ) : (
                <span className="ant-form-text">{user?.first_name}</span>
              )}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Text strong>Apellidos:</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="last_name"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa un apellido',
                },
              ]}
            >
              {editing ? (
                <Input placeholder="Ingresa un apellido" />
              ) : (
                <span className="ant-form-text">{user?.last_name}</span>
              )}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Text strong>Corro electrónico:</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="email"
              style={{ margin: 0 }}
              rules={[
                {
                  type: 'email',
                  message: 'Correo electrónico inválido',
                },
                {
                  required: true,
                  message: 'Por favor ingresa un correo electrónico',
                },
              ]}
            >
              {editing ? (
                <Input placeholder="Ingresa un correo" />
              ) : (
                <span className="ant-form-text">{user?.email}</span>
              )}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Text strong>Teléfono:</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="mobile"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa un teléfono',
                },
                () => ({
                  validator(_, value) {
                    if (value && !isValidPhoneNumber(value)) {
                      return Promise.reject(new Error('El teléfono ingresado es inválido'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {editing ? (
                <Input placeholder="Ingresa un teléfono" />
              ) : (
                <span className="ant-form-text">{user?.mobile || '-'}</span>
              )}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Text strong>RUT:</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="rut"
              style={{ margin: 0 }}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!isValidRut(value)) {
                      return Promise.reject(new Error('El RUT ingresado es inválido'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {editing ? (
                <RutInput placeholder="Ingresa un RUT" />
              ) : (
                <span className="ant-form-text">{user?.rut ? formatRut(user.rut) : '-'}</span>
              )}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Text strong>Dirección:</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="address"
              style={{ margin: 0 }}
            >
              {editing ? (
                <Input placeholder="Ingresa una dirección" />
              ) : (
                <span className="ant-form-text">{user?.address || '-'}</span>
              )}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Text strong>Comuna:</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="commune"
              style={{ margin: 0 }}
            >
              {editing ? (
                <Input placeholder="Ingresa una comuna" />
              ) : (
                <span className="ant-form-text">{user?.commune || '-'}</span>
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
}
