import React, { useEffect, useState } from 'react';
import {
  Button, Col, Input, PageHeader, Row, Table,
} from 'antd';
import type { TablePaginationConfig } from 'antd/lib/table';
import type { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import {
  Breed, Pet, Species,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import {
  defaultPagination, defaultParams, handleTableChange, renderDate,
} from '../../utils/tables';
import Loading from '../../components/Loading';
import { showErrorModal } from '../../utils/showErrorModal';
import useUserData from '../../hooks/useUserData';

const { Search } = Input;

interface FetchPetsParams {
  limit?: number;
  offset?: number;
  ordering?: string;
  breed__name__in?: string;
  species__name__in?: string;
  search? :string;
}

export default function Pets() {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const [pets, setPets] = useState<Pet[]>([]);
  const [species, setSpecies] = useState<Species[]>();
  const [breeds, setBreeds] = useState<Breed[]>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [fetchParams, setFetchParams] = useState<FetchPetsParams>(defaultParams);

  const fetchPetsList = (
    newPagination: TablePaginationConfig,
    params: FetchPetsParams = {},
  ) => new Promise(
    (resolve: (value: Pet[]) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/pets/`,
        { params },
      ).then((response) => {
        setPets(response.data.results);
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        resolve(response.data.results);
      }).catch((e) => {
        showErrorModal();
        reject(e);
      });
    },
  );

  useEffect(() => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/pets/species/`,
    ).then((speciesResponse) => {
      const speciesList = speciesResponse.data;
      setSpecies(speciesList);
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/pets/breeds/`,
      ).then((breedsResponse) => {
        const breedsList = breedsResponse.data;
        setBreeds(breedsList);
        fetchPetsList(pagination, fetchParams).then(() => setLoadingData(false));
      }).catch(() => {
        showErrorModal();
        setLoadingData(false);
      });
    }).catch(() => {
      showErrorModal();
      setLoadingData(false);
    });
  }, []);

  const onTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: (SorterResult<Pet> | SorterResult<Pet>[]),
  ) => {
    handleTableChange(
      newPagination,
      filters,
      sorter,
      (pagConfig, params) => {
        fetchPetsList(pagConfig, params).then(() => setLoadingData(false));
      },
    );
  };

  const onChangeSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    setLoadingSearch(true);
    fetchPetsList(pagination, {
      ...fetchParams,
      search: (e.target as HTMLInputElement).value,
    }).then(() => setLoadingSearch(false));
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_value: string, record: Pet) => (
        <Link
          style={{ display: 'block', margin: '1rem 0' }}
          to={`/pets/${record.id}`}
          state={{ addBackButton: true }}
          key={record.id}
        >
          {record.name}
        </Link>
      ),
    },
    {
      title: 'Especie',
      dataIndex: ['species', 'name'],
      key: 'species__name',
      filters: species?.map((speciesValue) => (
        { text: speciesValue.name, value: speciesValue.name }
      )),
    },
    {
      title: 'Raza',
      dataIndex: ['breed', 'name'],
      key: 'breed__name',
      filters: breeds?.map((breed) => (
        { text: breed.name, value: breed.name }
      )),
      filterSearch: true,
    },
    {
      title: 'Sexo',
      dataIndex: 'sex',
      key: 'sex',
      filters: [
        { text: 'Hembra', value: 'hembra' },
        { text: 'Macho', value: 'macho' },
      ],
    },
    {
      title: 'Edad',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Fecha de nacimiento',
      dataIndex: 'birth_date',
      key: 'birth_date',
      render: renderDate,
      sorter: true,
    },
    {
      title: 'Dueño o tutor',
      dataIndex: ['owner', 'full_name'],
      key: 'owner__last_name',
      sorter: true,
    },
    {
      title: 'Fecha de incorporación',
      dataIndex: 'date_added',
      key: 'date_added',
      render: renderDate,
      sorter: true,
    },
  ];

  return loadingData ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title="Mascotas"
        className="site-page-header"
        extra={userData?.permissions.includes('pets.add_pet') ? [
          <Button
            key="1"
            type="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => navigate('/pets/add')}
          >
            Agregar
          </Button>,
        ] : []}
      />

      <Row justify="end" style={{ marginBottom: 20 }}>
        <Col xs={24} sm={10} md={6} lg={6} xl={4}>
          <Search
            placeholder="Buscar"
            loading={loadingSearch}
            onChange={onChangeSearchValue}
          />
        </Col>
      </Row>

      <Table
        rowKey="id"
        dataSource={pets}
        columns={columns}
        scroll={{ x: 900 }}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
}
