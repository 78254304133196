import React, { useEffect, useState } from 'react';
import {
  Button,
  Card, Descriptions, Divider, List, PageHeader, Tag, Typography,
} from 'antd';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { PaginationConfig } from 'antd/lib/pagination';
import { Transportation } from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showErrorModal } from '../../utils/showErrorModal';
import Loading from '../../components/Loading';
import { defaultPagination, defaultParams, renderTime } from '../../utils/tables';

const { Title } = Typography;

interface FetchTransportationsParams {
  limit?: number;
  offset?: number;
}

type IndexedTransportations = { [key: string]: Transportation[] }

export default function Transportations() {
  const navigate = useNavigate();
  const [transportations, setTransportations] = useState<IndexedTransportations>({});
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationConfig>(defaultPagination);
  const [fetchParams, setFetchParams] = useState<FetchTransportationsParams>(defaultParams);

  const fetchTransportations = (
    newPagination: PaginationConfig,
    params: FetchTransportationsParams = {},
  ) => new Promise(
    (resolve: (value: Transportation[]) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/services/transportations/`,
        {
          params: {
            ...params,
            current_step: showCompleted ? 'completed' : 'pending',
          },
        },
      ).then((response) => {
        const orderedTransportations = _.groupBy(
          response.data.results,
          (value: Transportation) => moment(value.appointment.start).calendar(null, {
            sameDay: '[Hoy]',
            nextDay: '[Mañana]',
            sameElse: 'DD/MM/YYYY',
            lastDay: '[Ayer]',
            lastWeek: 'DD/MM/YYYY',
          }),
        );
        setTransportations(orderedTransportations as IndexedTransportations);
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        resolve(response.data.results);
      }).catch((e) => {
        showErrorModal();
        reject(e);
      });
    },
  );

  useEffect(() => {
    fetchTransportations(pagination, fetchParams).then(() => setLoadingData(false));
  }, [showCompleted]);

  const onListChange = (
    current: number,
    pageSize: number,
  ) => {
    fetchTransportations({
      current,
      pageSize,
    }, {
      limit: pageSize,
      offset: (current - 1) * pageSize,
    }).then(() => setLoadingData(false));
  };

  return loadingData ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title="Transporte"
        className="site-page-header"
        extra={showCompleted ? [
          <Button
            key="1"
            type="primary"
            onClick={() => setShowCompleted(false)}
          >
            Ver pendientes
          </Button>,
        ] : [
          <Button
            key="1"
            type="primary"
            onClick={() => setShowCompleted(true)}
          >
            Ver historial
          </Button>,
        ]}
      />

      <List
        dataSource={Object.keys(transportations)}
        pagination={{
          onChange: onListChange,
          ...pagination,
        }}
        renderItem={(key) => (
          <>
            <Title level={5}>
              {key}
            </Title>
            <List
              grid={{
                gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4,
              }}
              dataSource={transportations[key]}
              renderItem={(transportation) => (
                <List.Item>
                  <Card
                    className="transportation-details"
                    onClick={() => navigate(`/transportation/${transportation.id}`)}
                  >
                    <Tag
                      color={transportation.appointment.payment_confirmed ? 'green' : 'red'}
                      style={{ marginBottom: 10 }}
                    >
                      {transportation.appointment.payment_confirmed ? 'Pagado' : 'Pendiente de pago'}
                    </Tag>
                    <Descriptions
                      column={1}
                      title={(
                        <>
                          {transportation.appointment.pet.name}
                          {' - '}
                          {renderTime(transportation.appointment.start)}
                          hrs
                        </>
                      )}
                    >
                      <Descriptions.Item label="Tipo">
                        {transportation.type.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Hora llegada">
                        {renderTime(transportation.appointment.start)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Hora partida">
                        {renderTime(transportation.appointment.end)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Origen">{transportation.address}</Descriptions.Item>
                      <Descriptions.Item label="Destino">
                        Sucursal
                        {' '}
                        {transportation.appointment.clinic.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Estado">
                        {transportation.current_step.name}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </List.Item>
              )}
            />
            <Divider />
          </>
        )}
      />

    </div>
  );
}
