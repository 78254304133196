import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { RcFile } from 'rc-upload/es/interface';
import { Pet, Vaccination, Vaccine } from '../../types/base';
import { renderDate, sortDates } from '../../utils/tables';
import { axiosInstance } from '../../utils/axios-instance';
import { OptionValue } from '../../types/forms';
import {
  FilesPreviewList,
} from './Observations';
import Loading from '../../components/Loading';
import { showErrorModal } from '../../utils/showErrorModal';
import { filterOptions } from '../../utils/forms';
import useUserData from '../../hooks/useUserData';
import { validateFileSize } from '../../utils/validateFile';

const { Option } = Select;
const { Text } = Typography;

interface VaccinationFormValues {
  vaccine?: OptionValue;
  serial_number: string;
  expiration_date: Moment;
  date: Moment;
  next_vaccination_date: Moment;
  files: UploadFile[];
}

interface VaccinationModalFormProps {
  vaccination?: Vaccination
  pet: Pet;
  vaccines: Vaccine[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onComplete: (newVaccination: Vaccination) => void;
}

const uploadVaccinationFile = ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
  const filename = (file as RcFile)?.name;
  const formData = new FormData();
  formData.append('file', file, filename);
  formData.append('name', filename || 'file');
  axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/pets/vaccinations/files/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then((response) => {
    if (onSuccess) {
      onSuccess(response.data);
    }
  }).catch((e) => {
    if (onError) {
      onError(e);
    }
  });
};

export const removeVaccinationFile = (file: UploadFile) => {
  if (file.response) {
    axiosInstance.delete(
      `${process.env.REACT_APP_API_URL}/pets/vaccinations/files/${file.response.id}/`,
    );
  } else if (file.uid) {
    axiosInstance.delete(
      `${process.env.REACT_APP_API_URL}/pets/vaccinations/files/${file.uid}/`,
    );
  }
};

function VaccinationModalForm({
  vaccination, pet, vaccines, showModal, setShowModal, onComplete,
}: VaccinationModalFormProps) {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [vaccination]);

  const onAddVaccination = (values: VaccinationFormValues) => {
    setSaving(true);
    const { files, ...vaccinationData } = values;
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/pets/vaccinations/`,
      {
        ...vaccinationData,
        vaccine: vaccinationData.vaccine?.value,
        expiration_date: vaccinationData.expiration_date?.format('YYYY-MM-DD') || null,
        date: vaccinationData.date?.format('YYYY-MM-DD') || null,
        next_vaccination_date: vaccinationData.next_vaccination_date?.format('YYYY-MM-DD') || null,
        pet: pet.id,
        files: files ? files.map((file) => file.response.id) : [],
      },
    ).then((response) => {
      const newVaccination = response.data;
      setSaving(false);
      setShowModal(false);
      form.resetFields();
      onComplete(newVaccination);
    }).catch(() => {
      message.error(
        'Ocurrió un error al registrar la vacunación, por favor intenta nuevamente',
      );
      setSaving(false);
    });
  };

  const onUpdateVaccination = (values: VaccinationFormValues) => {
    if (vaccination) {
      setSaving(true);
      const { files, ...vaccinationData } = values;
      axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/pets/vaccinations/${vaccination.id}/`,
        {
          ...vaccinationData,
          vaccine: vaccinationData.vaccine?.value,
          expiration_date: vaccinationData.expiration_date?.format('YYYY-MM-DD') || null,
          date: vaccinationData.date?.format('YYYY-MM-DD') || null,
          next_vaccination_date: vaccinationData.next_vaccination_date?.format('YYYY-MM-DD') || null,
          pet: pet.id,
          files: files ? (
            [
              ...vaccination.files.map((file) => file.id),
              ...files.filter((file) => !!file.response?.id).map((file) => file.response?.id),
            ]
          ) : vaccination.files.map((file) => file.id),
        },
      ).then((response) => {
        const newVaccination = response.data;
        setSaving(false);
        setShowModal(false);
        form.resetFields();
        onComplete(newVaccination);
      }).catch(() => {
        message.error(
          'Ocurrió un error al actualizar la vacunación, por favor intenta nuevamente',
        );
        setSaving(false);
      });
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title="Registrar vacunación"
      visible={showModal}
      onOk={() => form.submit()}
      confirmLoading={saving}
      onCancel={() => setShowModal(false)}
    >
      <Form
        form={form}
        onFinish={vaccination ? onUpdateVaccination : onAddVaccination}
        autoComplete="off"
        size="small"
        initialValues={vaccination ? {
          vaccine: { value: `${vaccination.vaccine.id}`, label: vaccination.vaccine.name },
          serial_number: vaccination.serial_number,
          expiration_date: moment(vaccination.expiration_date),
          date: moment(vaccination.date),
          next_vaccination_date: moment(vaccination.next_vaccination_date),
          files: vaccination?.files.map((file) => ({
            uid: file.id,
            name: file.name,
            status: 'done',
            url: file.file_url,
          })),
          send_reminder: !!vaccination.reminder,
        } : {}}
      >
        <Row gutter={[8, 32]} align="top">
          <Col span={12}>
            <Text strong>Vacuna:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vaccine"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor selecciona una vacuna',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una vacuna"
                labelInValue
                showSearch
                filterOption={filterOptions}
                style={{ width: '100%' }}
              >
                {vaccines?.map((vaccine) => (
                  <Option key={vaccine.id}>{vaccine.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Text strong>Nº de serie:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="serial_number"
              style={{ margin: 0 }}
            >
              <Input placeholder="Ingresa un número" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Text strong>Fecha de vencimiento:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="expiration_date"
              style={{ margin: 0 }}
            >
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Text strong>Fecha de vacunación:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="date"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor selecciona una fecha',
                },
              ]}
            >
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Text strong>Próxima vacunación:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="next_vaccination_date"
              style={{ margin: 0 }}
            >
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Text strong>Multimedia:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="files"
              style={{ margin: 0 }}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                customRequest={uploadVaccinationFile}
                onRemove={removeVaccinationFile}
                listType="picture"
                maxCount={4}
                accept="image/*, video/*, application/msword, text/plain, application/pdf, .doc, .docx"
                beforeUpload={validateFileSize}
              >
                <Button icon={<UploadOutlined />}>Cargar archivo</Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="send_reminder" valuePropName="checked" style={{ margin: 0 }}>
              <Checkbox>Enviar un recordatorio una semana antes de la próxima vacunación</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

interface VaccinationsParams {
  pet: Pet;
  onCloseModal: () => void;
  showAddVaccinationForm: boolean;
  setShowAddVaccinationForm: (value: boolean) => void;
  canAddVaccination: boolean;
}

export default function Vaccinations({
  pet,
  onCloseModal,
  showAddVaccinationForm,
  setShowAddVaccinationForm,
  canAddVaccination,
}: VaccinationsParams) {
  const { userData } = useUserData();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [vaccines, setVaccines] = useState<Vaccine[]>([]);
  const [vaccinationToEdit, setVaccinationToEdit] = useState<Vaccination>();
  const [showEditVaccinationForm, setShowEditVaccinationForm] = useState<boolean>(false);

  useEffect(() => {
    if (canAddVaccination) {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/pets/vaccines/`,
      ).then((response) => {
        setVaccines(response.data);
        setLoadingData(false);
      }).catch(() => {
        showErrorModal();
        setLoadingData(false);
      });
    } else {
      setLoadingData(false);
    }
  }, []);

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: renderDate,
      sorter: (a: Vaccination, b: Vaccination) => sortDates(a.date, b.date),
    },
    {
      title: 'Vacuna',
      dataIndex: ['vaccine', 'name'],
      key: 'vaccine',
    },
    {
      title: 'Nº de serie',
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
    {
      title: 'Fecha expiración',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      render: renderDate,
      sorter: (a: Vaccination, b: Vaccination) => sortDates(a.expiration_date, b.expiration_date),
    },
    {
      title: 'Próxima vacunación',
      dataIndex: 'next_vaccination_date',
      key: 'next_vaccination_date',
      render: renderDate,
      sorter: (a: Vaccination, b: Vaccination) => sortDates(
        a.next_vaccination_date,
        b.next_vaccination_date,
      ),
    },
    ...userData?.permissions.includes('pets.change_vaccination') ? [
      {
        title: '',
        key: 'edit',
        render: (_value: string, record: Vaccination) => (
          <Button
            type="link"
            size="small"
            onClick={() => {
              setVaccinationToEdit(record);
              setShowEditVaccinationForm(true);
            }}
          >
            Editar
          </Button>
        ),
        sorter: (a: Vaccination, b: Vaccination) => sortDates(
          a.next_vaccination_date,
          b.next_vaccination_date,
        ),
      },
    ] : [],
  ];

  return loadingData ? (
    <Loading height="calc(100vh - 64px - 64px - 93px)" />
  ) : (
    <>
      <Table
        rowKey="id"
        dataSource={pet.vaccinations}
        columns={columns}
        scroll={{ x: 900 }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Descriptions title={`${record.vaccine.name} (${renderDate(record.date)})`} column={1}>
                <Descriptions.Item label="Descripción">
                  {record.vaccine.description}
                </Descriptions.Item>
                <Descriptions.Item label="Nº de serie">
                  {record.serial_number}
                </Descriptions.Item>
                <Descriptions.Item label="Fecha de vencimiento">
                  {renderDate(record.expiration_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Próxima vacunación">
                  {renderDate(record.next_vaccination_date)}
                </Descriptions.Item>
              </Descriptions>

              {record.files.length > 0 && (
                <FilesPreviewList files={record.files} />
              )}
            </>
          ),
        }}
      />
      <VaccinationModalForm
        pet={pet}
        vaccines={vaccines}
        showModal={showAddVaccinationForm}
        setShowModal={setShowAddVaccinationForm}
        onComplete={onCloseModal}
      />
      <VaccinationModalForm
        vaccination={vaccinationToEdit}
        pet={pet}
        vaccines={vaccines}
        showModal={showEditVaccinationForm}
        setShowModal={setShowEditVaccinationForm}
        onComplete={onCloseModal}
      />
    </>
  );
}
