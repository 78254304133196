import React, { useEffect, useState } from 'react';
import {
  Col, Modal, PageHeader, Row, Switch, Typography,
} from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Appointment, User } from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showErrorModal } from '../../utils/showErrorModal';
import Loading from '../../components/Loading';
import {
  renderDate, renderTime, renderUser,
} from '../../utils/tables';
import useUserData from '../../hooks/useUserData';

const { confirm } = Modal;
const { Text } = Typography;

export default function AppointmentDetails() {
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const { userData } = useUserData();
  const [appointment, setAppointment] = useState<Appointment>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [paymentConfirmed, setPaymentConfirmed] = useState<boolean>(false);
  const [savingPaymentStatus, setSavingPaymentStatus] = useState<boolean>(false);

  useEffect(() => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/services/appointments/${appointmentId}/`,
    ).then((response) => {
      setAppointment(response.data);
      setPaymentConfirmed(response.data.payment_confirmed);
      setLoadingData(false);
    }).catch(() => {
      showErrorModal();
      setLoadingData(false);
    });
  }, []);

  function renderOwner(owner: User) {
    return (
      userData?.permissions.includes('users.view_user') ? (
        <Link
          style={{ margin: '0' }}
          to={`/owners/${owner.id}`}
          key={owner.id}
        >
          {renderUser(owner)}
        </Link>
      ) : (
        <span className="ant-form-text">{renderUser(owner)}</span>
      )
    );
  }

  function changePaymentStatus(checked: boolean) {
    setSavingPaymentStatus(true);
    axiosInstance.put(
      `${process.env.REACT_APP_API_URL}/services/appointments/${appointmentId}/update_payment_status/`,
      {
        payment_confirmed: checked,
      },
    ).then((response) => {
      setAppointment(response.data);
      setSavingPaymentStatus(false);
    }).catch(() => {
      showErrorModal();
      cancelChangePaymentStatus();
    });
  }

  function cancelChangePaymentStatus() {
    setSavingPaymentStatus(false);
    setPaymentConfirmed(appointment?.payment_confirmed || false);
  }

  return loadingData || !appointment ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title={`${appointment.service} ${appointment.pet.name}`}
        onBack={() => navigate(-1)}
        className="site-page-header"
      />

      <div style={{ maxWidth: 500, paddingTop: 20, paddingBottom: 20 }}>
        <Row gutter={[8, 24]} align="top">
          <Col span={12}>
            <Text strong>Servicio:</Text>
          </Col>
          <Col span={12}>
            <span className="ant-form-text">{appointment.service}</span>
          </Col>

          <Col span={12}>
            <Text strong>Mascota:</Text>
          </Col>
          <Col span={12}>
            <span className="ant-form-text">
              <Link
                style={{ margin: '0' }}
                to={`/pets/${appointment.pet.id}`}
                key={appointment.pet.id}
              >
                {appointment.pet.name}
              </Link>
            </span>
          </Col>

          <Col span={12}>
            <Text strong>Dueño o Tutor:</Text>
          </Col>
          <Col span={12}>
            <span className="ant-form-text">{renderOwner(appointment.pet.owner)}</span>
          </Col>

          <Col span={12}>
            <Text strong>Sucursal:</Text>
          </Col>
          <Col span={12}>
            <span className="ant-form-text">{appointment.clinic.name}</span>
          </Col>

          <Col span={12}>
            <Text strong>Profesional encargado:</Text>
          </Col>
          <Col span={12}>
            <span className="ant-form-text">{appointment.designated_employee.full_name}</span>
          </Col>

          <Col span={12}>
            <Text strong>Fecha:</Text>
          </Col>
          <Col span={12}>
            <span className="ant-form-text">{renderDate(appointment.start)}</span>
          </Col>

          <Col span={12}>
            <Text strong>Hora:</Text>
          </Col>
          <Col span={12}>
            <span className="ant-form-text">
              {renderTime(appointment.start)}
              {' '}
              a
              {' '}
              {renderTime(appointment.end)}
            </span>
          </Col>

          <Col span={12}>
            <Text strong>Pagada:</Text>
          </Col>
          <Col span={12}>
            {userData?.permissions.includes('services.change_appointment') ? (
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={paymentConfirmed}
                disabled={appointment.payment_confirmed}
                onChange={(checked) => {
                  setPaymentConfirmed(checked);
                  setSavingPaymentStatus(true);
                  confirm({
                    title: '¿Seguro que quieres modificar el estado del pago?',
                    icon: <ExclamationCircleOutlined />,
                    onOk: () => changePaymentStatus(checked),
                    onCancel: cancelChangePaymentStatus,
                  });
                }}
                loading={savingPaymentStatus}
              />
            ) : (
              <span className="ant-form-text">{appointment.payment_confirmed ? 'Si' : 'No'}</span>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}
