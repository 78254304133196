import React, { useEffect, useState } from 'react';
import {
  Button, Form, Input, message, Typography,
} from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import useWindowSize from '../../hooks/useWindowSize';
import { noAuthAxios } from '../../utils/axios-instance';
import { FormContainer } from '../SignIn';
import Loading from '../../components/Loading';
import { Token } from '../../types/base';

const { Title } = Typography;

interface NewPasswordFormValues {
  password: string;
}

interface NewPasswordResponseData {
  password: string[];
}

export type PasswordFormProps = {
  isInitialPassword: boolean;
  setToken?: (userToken: Token) => void;
};

function PasswordForm({ isInitialPassword, setToken }: PasswordFormProps) {
  const [windowWidth, windowHeight] = useWindowSize();
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [validatingToken, setValidatingToken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('false');

  useEffect(() => {
    const emailParam = searchParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    } else {
      navigate('/');
    }
  });

  useEffect(() => {
    setValidatingToken(true);
    noAuthAxios.post(
      `${process.env.REACT_APP_API_URL}/users/validate_token/`,
      { token },
    ).then(() => {
      setValidatingToken(false);
    }).catch(() => {
      setValidatingToken(false);
      navigate('/signin');
    });
  }, [token]);

  const onSuccess = (loggedIn: boolean) => {
    message.success(isInitialPassword ? 'Se creó la contraseña.' : 'Se modificó la contraseña.');
    if (loggedIn) {
      navigate('/', { replace: true });
    } else {
      navigate('/signin', { replace: true });
    }
  };

  const onFinish = (values: NewPasswordFormValues) => {
    setLoading(true);
    noAuthAxios.post(
      `${process.env.REACT_APP_API_URL}/users/set_password/`,
      {
        ...values,
        token,
      },
    ).then(() => {
      if (email) {
        noAuthAxios.post(
          `${process.env.REACT_APP_API_URL}/token/`,
          {
            email,
            ...values,
          },
        ).then((response) => {
          const userToken: Token = response.data;
          setLoading(false);
          if (setToken) {
            setToken(userToken);
            onSuccess(true);
          }
        }).catch(() => {
          onSuccess(false);
        });
      } else {
        onSuccess(false);
      }
    }).catch((e) => {
      const errorMessages = (e.response?.data as NewPasswordResponseData).password || [];
      if (errorMessages.join(', ').includes('This password is too common')) {
        message.error('La contraseña es demasiado común.');
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente.');
      }
      setLoading(false);
    });
  };

  return validatingToken ? <Loading /> : (
    <div style={{ display: 'flex' }}>
      {windowWidth !== 0 && windowHeight !== 0 && (
        <FormContainer windowHeight={windowHeight} windowWidth={windowWidth}>
          <Title level={4}>{isInitialPassword ? 'Crear contraseña' : 'Cambiar contraseña'}</Title>

          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            style={{ paddingTop: 18 }}
          >
            <Form.Item
              label={isInitialPassword ? 'Contraseña' : 'Nueva contraseña'}
              name="password"
              rules={[
                { required: true, message: 'Por favor ingresa una contraseña' },
                () => ({
                  validator(_, value) {
                    if (value.length < 8) {
                      return Promise.reject(
                        new Error('La contraseña debe tener al menos 8 caracteres'),
                      );
                    } if (!/\d/.test(value)) {
                      return Promise.reject(
                        new Error('La contraseña debe tener al menos un número'),
                      );
                    } if (!/[a-zA-Z]/.test(value)) {
                      return Promise.reject(
                        new Error('La contraseña debe tener al menos una letra'),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={isInitialPassword ? 'Confirmar contraseña' : 'Confirmar nueva contraseña'}
              name="password2"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Por favor confirma la contraseña',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item style={{ marginBottom: 8 }}>
              <Button type="primary" htmlType="submit" block>
                {loading ? (
                  <LoadingOutlined />
                ) : 'Crear contraseña'}
              </Button>
            </Form.Item>
          </Form>

        </FormContainer>
      )}
    </div>
  );
}

export default PasswordForm;
