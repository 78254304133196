import React, { useState } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Moment } from 'moment';
import {
  Pet, Reminder, ReminderType,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import {
  renderDateTimeWithFormat,
} from '../../utils/tables';

const { TextArea } = Input;
const { Option } = Select;
const { Text, Title } = Typography;

function reminderColor(reminderType: ReminderType) {
  if (reminderType === 'vacunación') {
    return '#cb7312';
  } if (reminderType === 'consulta') {
    return '#0952a3';
  }
  return '#388e84';
}

interface RemindersFormValues {
  content: string;
  occurrence: Moment;
  type: string;
}

interface ReminderModalFormProps {
  pet: Pet;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onComplete: (newReminder: Reminder) => void;
}

function ReminderModalForm({
  pet, showModal, setShowModal, onComplete,
}: ReminderModalFormProps) {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  const onAddReminder = (values: RemindersFormValues) => {
    setSaving(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/reminders/`,
      {
        ...values,
        occurrence: values.occurrence?.format() || null,
        type: values.type || 'otro',
        recipient: pet.owner.id,
        pet: pet.id,
      },
    ).then((response) => {
      setSaving(false);
      setShowModal(false);
      form.resetFields();
      onComplete(response.data);
    }).catch(() => {
      message.error(
        'Ocurrió un error al agregar el recordatorio, por favor intenta nuevamente',
      );
      setSaving(false);
    });
  };

  return (
    <Modal
      title="Agregar un recordatorio"
      visible={showModal}
      onOk={() => form.submit()}
      confirmLoading={saving}
      onCancel={() => setShowModal(false)}
    >
      <Form
        form={form}
        onFinish={onAddReminder}
        autoComplete="off"
        size="small"
      >
        <Row gutter={[8, 16]} align="middle">
          <Col span={24}>
            <Text strong>Recordatorio:</Text>
          </Col>
          <Col span={24}>
            <Form.Item
              name="content"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa el contenido del recordatorio',
                },
              ]}
            >
              <TextArea rows={4} maxLength={255} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text strong>Fecha y hora del recordatorio:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="occurrence"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor selecciona una fecha y hora',
                },
              ]}
            >
              <DatePicker format="DD/MM/YYYY HH:mm" showTime style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Text strong>Tipo:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              style={{ margin: 0 }}
            >
              <Select allowClear placeholder="Selecciona un tipo" style={{ width: '100%' }}>
                <Option value="vacunación">Vacunación</Option>
                <Option value="consulta">Consulta</Option>
                <Option value="otro">Otro</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

interface RemindersParams {
  pet: Pet;
  onCloseModal: () => void;
  showAddReminderForm: boolean;
  setShowAddReminderForm: (value: boolean) => void;
}

export default function Reminders({
  pet, onCloseModal, showAddReminderForm, setShowAddReminderForm,
}: RemindersParams) {
  const reminders = _.groupBy(
    _.orderBy(pet.reminders, 'occurrence'),
    (value: Reminder) => renderDateTimeWithFormat(value.occurrence, 'D MMM YYYY'),
  );

  return (
    <>
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={Object.keys(reminders)}
        split={false}
        renderItem={(key) => (
          <List.Item>
            <Row align="middle">
              <Col span={24}>
                <Title level={5} style={{ margin: 0 }}>
                  {key}
                </Title>
              </Col>
              <Col flex="auto">
                {reminders[key].map((reminder) => (
                  <Row gutter={8} key={reminder.id}>
                    <Col>
                      <FontAwesomeIcon icon={faCircle} size="xs" color={reminderColor(reminder.type)} />
                    </Col>
                    <Col>
                      {reminder.content}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </List.Item>
        )}
      />
      <ReminderModalForm
        pet={pet}
        showModal={showAddReminderForm}
        setShowModal={setShowAddReminderForm}
        onComplete={onCloseModal}
      />
    </>
  );
}
