import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Form, PageHeader } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PetForm, { fetchInitialPetFormData } from './PetForm';
import Loading from '../../components/Loading';
import { Breed, Species, User } from '../../types/base';
import { showErrorModal } from '../../utils/showErrorModal';

export default function AddPet() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>();
  const [species, setSpecies] = useState<Species[]>();
  const [breeds, setBreeds] = useState<Breed[]>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    fetchInitialPetFormData().then(({ usersData, speciesData, breedsData }) => {
      setUsers(usersData);
      setSpecies(speciesData);
      setBreeds(breedsData);
      setLoadingData(false);
    }).catch(() => {
      showErrorModal();
      setLoadingData(false);
    });
  }, []);

  return loadingData ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title="Agregar mascota"
        className="site-page-header"
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key="1"
            type="primary"
            icon={saving ? <LoadingOutlined /> : null}
            onClick={() => form.submit()}
          >
            Guardar
          </Button>,
        ]}
      />
      <PetForm
        form={form}
        species={species}
        initialOwners={users}
        initialBreeds={breeds}
        setSaving={setSaving}
        onComplete={() => navigate('/pets')}
      />
    </div>
  );
}
