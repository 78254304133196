import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, message, Modal, PageHeader, Row, Select, Steps, Switch, Typography, Upload,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { RcFile } from 'rc-upload/es/interface';
import { UploadFile } from 'antd/es/upload/interface';
import {
  Transportation,
  TransportationStep,
  TransportationStepChange,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showErrorModal } from '../../utils/showErrorModal';
import Loading from '../../components/Loading';
import { renderTime } from '../../utils/tables';
import { OptionValue } from '../../types/forms';
import useUserData from '../../hooks/useUserData';
import { validateFileSize, validateFileFormat } from '../../utils/validateFile';

const { confirm } = Modal;
const { Option } = Select;
const { Step } = Steps;
const { Text, Title } = Typography;

type LatestStepChange = { [key: number]: Moment }

interface ChangeCurrentStepFormValues {
  selectedStep: OptionValue;
  files: UploadFile[];
}

export interface ChangeCurrentStepModalFormProps {
  transportation: Transportation;
  transportationSteps: TransportationStep[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onComplete: () => void;
}

function ChangeCurrentStepModalForm({
  transportation, transportationSteps, showModal, setShowModal, onComplete,
}: ChangeCurrentStepModalFormProps) {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const onUpdateStatus = (values: ChangeCurrentStepFormValues) => {
    const { selectedStep, files } = values;
    if (selectedStep && transportation.current_step.id.toString() !== selectedStep.value) {
      setSaving(true);
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/services/transportation_step_changes/`,
        {
          transportation: transportation.id,
          step: selectedStep.value,
          images: files ? files.map((image) => image.response.id) : [],
        },
      ).then(() => {
        setSaving(false);
        setShowModal(false);
        form.resetFields();
        onComplete();
      }).catch(() => {
        message.error(
          'Ocurrió un error al modificar el estado, por favor intenta nuevamente',
        );
        setSaving(false);
      });
    } else {
      message.error(
        'No se ha cambiado el estado actual',
      );
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadStepChangeImage = ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
    setDisableSaveButton(true);
    const filename = (file as RcFile)?.name;
    const formData = new FormData();
    formData.append('image', file, filename);
    formData.append('name', filename || 'file');
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/services/transportation_step_changes/images/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).then((response) => {
      setDisableSaveButton(false);
      if (onSuccess) {
        onSuccess(response.data);
      }
    }).catch((e) => {
      setDisableSaveButton(false);
      if (onError) {
        onError(e);
      }
    });
  };

  const removeStepChangeImage = (file: UploadFile) => {
    if (file.response) {
      axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/services/transportation_step_changes/images/${file.response.id}/`,
      );
    } else if (file.uid) {
      axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/services/transportation_step_changes/images/${file.uid}/`,
      );
    }
  };

  return (
    <Modal
      title="Modificar estado"
      visible={showModal}
      onOk={() => form.submit()}
      confirmLoading={saving}
      onCancel={() => {
        form.resetFields();
        setShowModal(false);
      }}
      okText="Guardar"
      okButtonProps={{ disabled: disableSaveButton }}
    >
      <Form
        form={form}
        onFinish={onUpdateStatus}
        autoComplete="off"
        size="small"
        initialValues={{
          selectedStep: {
            value: `${transportation.current_step.id}`,
            label: transportation.current_step.name,
          },
        }}
      >
        <Row gutter={[8, 32]} align="top">
          <Col span={10}>
            <Text strong>Nuevo estado:</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="selectedStep"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor selecciona un estado',
                },
              ]}
            >
              <Select
                labelInValue
                style={{ width: '100%' }}
              >
                {transportationSteps?.map((step) => (
                  <Option key={step.id}>{step.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={10}>
            <Text strong>Foto o video (máx. 10s):</Text>
          </Col>
          <Col span={14}>
            <Form.Item
              name="files"
              style={{ margin: 0 }}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                customRequest={uploadStepChangeImage}
                onRemove={removeStepChangeImage}
                listType="picture"
                maxCount={4}
                accept="image/*, video/*"
                beforeUpload={(file: RcFile) => (
                  validateFileSize(file) === true
                  && validateFileFormat(
                    file,
                    [
                      'image/png',
                      'image/jpeg',
                      'image/gif',
                      'image/webp',
                      'video/mpeg',
                      'video/mp4',
                      'video/quicktime',
                    ],
                  ) === true ? true : Upload.LIST_IGNORE
                )}
              >
                <Button icon={<UploadOutlined />}>Cargar archivo</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default function TransportationDetails() {
  const navigate = useNavigate();
  const { transportationId } = useParams();
  const { userData } = useUserData();
  const [transportation, setTransportation] = useState<Transportation>();
  const [stepChanges, setStepChanges] = useState<LatestStepChange>({});
  const [transportationSteps, setTransportationSteps] = useState<TransportationStep[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [showChangeStepModal, setShowChangeStepModal] = useState<boolean>(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState<boolean>(false);
  const [savingPaymentStatus, setSavingPaymentStatus] = useState<boolean>(false);

  const fetchTransportations = () => new Promise(
    (resolve: (value: Transportation) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/services/transportations/${transportationId}/`,
      ).then((response) => {
        const transportationData = response.data;
        setTransportation(transportationData);
        setPaymentConfirmed(transportationData.appointment.payment_confirmed);
        setTransportationSteps(transportationData.type.possible_steps.sort(
          (a:TransportationStep, b:TransportationStep) => (a.order - b.order),
        ));
        resolve(transportationData);
      }).catch((e) => {
        showErrorModal();
        reject(e);
      });
    },
  );

  useEffect(() => {
    fetchTransportations().then(() => {
      setLoadingData(false);
    }).catch(() => {
      showErrorModal();
      setLoadingData(false);
    });
  }, []);

  useEffect(() => {
    if (transportation) {
      setStepChanges(
        _.reduce(
          transportation.step_changes,
          (result: LatestStepChange, current: TransportationStepChange) => {
            const currentChangedAt = moment(current.changed_at);
            if (!(current.step.id in result) || result[current.step.id] < currentChangedAt) {
              return {
                ...result,
                [current.step.id]: currentChangedAt,
              };
            }
            return result;
          },
          {},
        ),
      );
    }
  }, [transportation]);

  function changePaymentStatus(checked: boolean) {
    if (transportation) {
      setSavingPaymentStatus(true);
      axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/services/appointments/${transportation.appointment.id}/update_payment_status/`,
        {
          payment_confirmed: checked,
        },
      ).then((response) => {
        setTransportation({
          ...transportation,
          appointment: response.data,
        });
        setSavingPaymentStatus(false);
      }).catch(() => {
        showErrorModal();
        cancelChangePaymentStatus();
      });
    }
  }

  function cancelChangePaymentStatus() {
    setSavingPaymentStatus(false);
    setPaymentConfirmed(transportation?.appointment.payment_confirmed || false);
  }

  function getStepSubtitle(stepId: number) {
    if (stepId in stepChanges) {
      return stepChanges[stepId].format('DD/MM/YYYY HH:mm:ss');
    }
    return null;
  }

  return loadingData || !transportation ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title={`${transportation.appointment.pet.name} - ${renderTime(transportation.appointment.start)}hrs`}
        onBack={() => navigate(-1)}
        className="site-page-header"
      />

      <div style={{
        maxWidth: 500, paddingTop: 20, paddingBottom: 20, marginBottom: 20,
      }}
      >
        <Row gutter={[8, 24]} align="middle">
          <Col span={10}>
            <Text strong>Tipo:</Text>
          </Col>
          <Col span={14}>
            <span>{transportation.type.name}</span>
          </Col>

          <Col span={10}>
            <Text strong>Hora llegada:</Text>
          </Col>
          <Col span={14}>
            <span>{renderTime(transportation.appointment.start)}</span>
          </Col>

          <Col span={10}>
            <Text strong>Hora partida:</Text>
          </Col>
          <Col span={14}>
            <span>{renderTime(transportation.appointment.end)}</span>
          </Col>

          <Col span={10}>
            <Text strong>Origen:</Text>
          </Col>
          <Col span={14}>
            <p style={{ margin: 0 }}>
              {transportation.address}
            </p>
            {transportation.commune && <p style={{ margin: 0 }}>{transportation.commune}</p>}
          </Col>

          <Col span={10}>
            <Text strong>Destino:</Text>
          </Col>
          <Col span={14}>
            <span>
              Sucursal
              {' '}
              {transportation.appointment.clinic.name}
            </span>
          </Col>

          <Col span={10}>
            <Text strong>Dueño o tutor:</Text>
          </Col>
          <Col span={14}>
            <span>{transportation.appointment.pet.owner.full_name}</span>
          </Col>

          <Col span={10}>
            <Text strong>Pagado:</Text>
          </Col>
          <Col span={14}>
            {userData?.permissions.includes('services.change_appointment') ? (
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={paymentConfirmed}
                disabled={transportation.appointment.payment_confirmed}
                onChange={(checked) => {
                  setPaymentConfirmed(checked);
                  setSavingPaymentStatus(true);
                  confirm({
                    title: '¿Seguro que quieres modificar el estado del pago?',
                    icon: <ExclamationCircleOutlined />,
                    onOk: () => changePaymentStatus(checked),
                    onCancel: cancelChangePaymentStatus,
                  });
                }}
                loading={savingPaymentStatus}
              />
            ) : (
              <span className="ant-form-text">
                {transportation.appointment.payment_confirmed ? 'Si' : 'No'}
              </span>
            )}
          </Col>

          <Col span={10}>
            <Text strong>Estado actual:</Text>
          </Col>
          <Col span={14}>
            <Row gutter={[8, 8]} align="middle">
              <Col xs={24} sm={16}>
                <span>{transportation.current_step.name}</span>
              </Col>
              <Col xs={24} sm={8}>
                <Button
                  type="primary"
                  block
                  onClick={() => setShowChangeStepModal(true)}
                >
                  Cambiar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Title level={4}>Estado</Title>
      {transportationSteps && (
        <Steps
          direction="vertical"
          size="small"
          current={transportationSteps
            .findIndex((step) => step.order === transportation.current_step.order)}
        >
          {transportationSteps.map((step) => (
            <Step
              key={step.id}
              title={step.name}
              // title={step.order}
              description={step.description}
              subTitle={
                step.order <= transportation.current_step.order ? getStepSubtitle(step.id) : null
              }
            />
          ))}
        </Steps>
      )}

      <ChangeCurrentStepModalForm
        transportation={transportation}
        transportationSteps={transportationSteps}
        showModal={showChangeStepModal}
        setShowModal={setShowChangeStepModal}
        onComplete={() => {
          setLoadingData(true);
          fetchTransportations().then(() => {
            setLoadingData(false);
          }).catch(() => {
            showErrorModal();
            setLoadingData(false);
          });
        }}
      />
    </div>
  );
}
