import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Image,
  Input, InputNumber,
  List,
  message,
  Modal,
  Row,
  Space,
  Typography,
  Upload,
} from 'antd';
import { EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import type { UploadFile } from 'antd/es/upload/interface';
import type { PaginationConfig } from 'antd/lib/pagination';
import { RcFile } from 'rc-upload/es/interface';
import {
  Observation, ObservationFile, Pet, VaccinationFile,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import {
  defaultPagination,
  defaultParams,
  renderDate,
  renderNumericalValue,
} from '../../utils/tables';
import fileIcon from '../../assets/icons/file.svg';
import { showErrorModal } from '../../utils/showErrorModal';
import Loading from '../../components/Loading';
import { validateFileSize } from '../../utils/validateFile';

const { TextArea } = Input;
const { Link, Text } = Typography;

export const uploadObservationFile = ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
  const filename = (file as RcFile)?.name;
  const formData = new FormData();
  formData.append('file', file, filename);
  formData.append('name', filename || 'file');
  axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/pets/observations/files/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then((response) => {
    if (onSuccess) {
      onSuccess(response.data);
    }
  }).catch((e) => {
    if (onError) {
      onError(e);
    }
  });
};

export const removeObservationFile = (file: UploadFile) => {
  if (file.response) {
    axiosInstance.delete(
      `${process.env.REACT_APP_API_URL}/pets/observations/files/${file.response.id}/`,
    );
  } else if (file.uid) {
    axiosInstance.delete(
      `${process.env.REACT_APP_API_URL}/pets/observations/files/${file.uid}/`,
    );
  }
};

interface ObservationFormValues {
  temperature: number;
  weight: number;
  heart_rate: number;
  respiratory_rate: number;
  anamnesis: string;
  conclusions: string;
  files: UploadFile[];
}

interface AddObservationModalFormProps {
  pet: Pet;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onComplete: (newObservation: Observation) => void;
}

function AddObservationModalForm({
  pet, showModal, setShowModal, onComplete,
}: AddObservationModalFormProps) {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  const onAddObservation = (values: ObservationFormValues) => {
    setSaving(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/pets/observations/`,
      {
        ...values,
        files: values.files ? values.files.map((file) => file.response.id) : [],
        pet: pet.id,
      },
    ).then((response) => {
      const observation = response.data;
      setSaving(false);
      setShowModal(false);
      form.resetFields();
      onComplete(observation);
    }).catch(() => {
      message.error(
        'Ocurrió un error al agregar la observación, por favor intenta nuevamente',
      );
      setSaving(false);
    });
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title="Agregar una observación a la ficha"
      visible={showModal}
      onOk={() => form.submit()}
      confirmLoading={saving}
      onCancel={() => {
        form.resetFields();
        setShowModal(false);
      }}
      okText="Guardar"
    >
      <Form
        form={form}
        onFinish={onAddObservation}
        autoComplete="off"
        size="small"
        requiredMark={false}
      >
        <Row gutter={[8, 0]} align="top">
          <Col span={12}>
            <Text strong>Temperatura:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="temperature"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa la temperatura',
                },
              ]}
            >
              <InputNumber addonAfter="°C" decimalSeparator="," min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text strong>Peso:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="weight"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa el peso',
                },
              ]}
            >
              <InputNumber addonAfter="kg" decimalSeparator="," min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text strong>Frecuencia cardiaca:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="heart_rate"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa la frecuencia cardiaca',
                },
              ]}
            >
              <InputNumber decimalSeparator="," style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text strong>Frecuencia respiratoria:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="respiratory_rate"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa la frecuencia respiratoria',
                },
              ]}
            >
              <InputNumber decimalSeparator="," style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: 18 }}>
            <Text strong>Anamnesis:</Text>
          </Col>
          <Col span={24}>
            <Form.Item
              name="anamnesis"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa la anamnesis',
                },
              ]}
            >
              <TextArea rows={4} maxLength={255} />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: 18 }}>
            <Text strong>Conclusiones:</Text>
          </Col>
          <Col span={24}>
            <Form.Item
              name="conclusions"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa las conclusiones',
                },
              ]}
            >
              <TextArea rows={4} maxLength={255} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text strong>Multimedia:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="files"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                customRequest={uploadObservationFile}
                onRemove={removeObservationFile}
                listType="picture"
                maxCount={4}
                accept="image/*, video/*, application/msword, text/plain, application/pdf, .doc, .docx"
                beforeUpload={validateFileSize}
              >
                <Button icon={<UploadOutlined />}>Cargar archivo</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

interface FilesPreviewListParams {
  files: ObservationFile[] | VaccinationFile[];
}

export function FilesPreviewList({ files }: FilesPreviewListParams) {
  return (
    <List
      grid={{
        gutter: 8,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      className="upload-list"
      itemLayout="horizontal"
      dataSource={files}
      renderItem={(file) => (
        <List.Item>
          <Card size="small">
            <Space>
              <Image
                src={file.file_url}
                height={40}
                fallback={fileIcon}
                preview={{
                  mask: <EyeOutlined />,
                }}
              />
              <Link ellipsis href={file.file_url} style={{ maxWidth: 180 }}>
                {file.name}
              </Link>
            </Space>
          </Card>
        </List.Item>
      )}
    />
  );
}

interface FetchObservationsParams {
  limit?: number;
  offset?: number;
  ordering?: string;
  search? :string;
}

interface ObservationsParams {
  pet: Pet;
  showAddObservationForm: boolean;
  setShowAddObservationForm: (value: boolean) => void;
}

export default function Observations({
  pet, showAddObservationForm, setShowAddObservationForm,
}: ObservationsParams) {
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [observations, setObservations] = useState<Observation[]>([]);
  const [pagination, setPagination] = useState<PaginationConfig>(defaultPagination);
  const [fetchParams, setFetchParams] = useState<FetchObservationsParams>(defaultParams);

  const fetchObservations = (
    newPagination: PaginationConfig,
    params: FetchObservationsParams = {},
  ) => new Promise(
    (resolve: (value: Pet[]) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/pets/observations/`,
        {
          params: {
            ...params,
            pet__id: pet.id,
          },
        },
      ).then((response) => {
        setObservations(response.data.results);
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        resolve(response.data.results);
      }).catch((e) => {
        showErrorModal();
        reject(e);
      });
    },
  );

  useEffect(() => {
    fetchObservations(pagination, fetchParams).then(() => setLoadingData(false));
  }, []);

  const onListChange = (
    current: number,
    pageSize: number,
  ) => {
    fetchObservations({
      current,
      pageSize,
    }, {
      limit: pageSize,
      offset: (current - 1) * pageSize,
    }).then(() => setLoadingData(false));
  };

  return loadingData ? (
    <Loading height="calc(100vh - 64px - 64px - 93px)" />
  ) : (
    <>
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={observations}
        pagination={{
          onChange: onListChange,
          ...pagination,
        }}
        renderItem={(observation) => (
          <List.Item>
            <List.Item.Meta
              description={(
                <>
                  <Descriptions
                    column={1}
                    title={renderDate(observation.created_at)}
                    size="small"
                    labelStyle={{ fontWeight: 600 }}
                  >
                    <Descriptions.Item label="Veterinario tratante">
                      {observation.created_by.full_name}
                    </Descriptions.Item>
                    {observation.temperature && (
                      <Descriptions.Item label="Temperatura">
                        {renderNumericalValue(observation.temperature)}
                        °C
                      </Descriptions.Item>
                    )}
                    {observation.weight && (
                      <Descriptions.Item label="Peso">
                        {renderNumericalValue(observation.weight)}
                        {' '}
                        kg
                      </Descriptions.Item>
                    )}
                    {observation.heart_rate && (
                      <Descriptions.Item label="Frecuencia cardiaca">
                        {observation.heart_rate}
                      </Descriptions.Item>
                    )}
                    {observation.respiratory_rate && (
                      <Descriptions.Item label="Frecuencia respiratoria">
                        {observation.respiratory_rate}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                  <Descriptions
                    column={1}
                    size="small"
                    layout="vertical"
                    labelStyle={{ fontWeight: 600 }}
                  >
                    {observation.anamnesis && (
                      <Descriptions.Item label="Anamnesis">
                        {observation.anamnesis}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Conclusiones">
                      {observation.conclusions}
                    </Descriptions.Item>
                  </Descriptions>

                  <div style={{ marginTop: 10 }}>
                    {observation?.files.length > 0 && (
                      <FilesPreviewList files={observation?.files} />
                    )}
                  </div>
                </>
              )}
            />
          </List.Item>
        )}
      />
      <AddObservationModalForm
        pet={pet}
        showModal={showAddObservationForm}
        setShowModal={setShowAddObservationForm}
        onComplete={() => {
          setLoadingData(true);
          fetchObservations(pagination, fetchParams).then(() => setLoadingData(false));
        }}
      />
    </>
  );
}
