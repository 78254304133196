import React, { useState } from 'react';
import {
  Form, Input, Button, message, Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import useWindowSize from '../hooks/useWindowSize';
import { noAuthAxios } from '../utils/axios-instance';
import { Token } from '../types/base';

const { Title } = Typography;

interface FormContainerProps {
  windowHeight: number;
  windowWidth: number;
}

export const FormContainer = styled.div<FormContainerProps>`
  margin: 40px auto;
  background-color: #edf4f3;
  width: 350px;
  height: fit-content;
  border-radius: 6px;
  padding: 20px 30px;
`;

type SignInProps = {
  setToken?: (userToken: Token) => void;
};

interface SignInFormValues {
  email: string;
  password: string;
}

function SignIn({ setToken }: SignInProps) {
  const [windowWidth, windowHeight] = useWindowSize();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: SignInFormValues) => {
    setLoading(true);
    noAuthAxios.post(
      `${process.env.REACT_APP_API_URL}/token/`,
      values,
    ).then((response) => {
      const userToken: Token = response.data;
      if (setToken) {
        setToken(userToken);
        navigate('/', { replace: true });
      } else {
        message.error('Ocurrió un error en la autenticación');
      }
      setLoading(false);
    }).catch(() => {
      message.error('Ocurrió un error en la autenticación');
      setLoading(false);
    });
  };

  const onPasswordReset = () => {
    navigate('/request-password-reset');
  };

  return (
    <div style={{ display: 'flex' }}>
      {windowWidth !== 0 && windowHeight !== 0 && (
        <FormContainer windowHeight={windowHeight} windowWidth={windowWidth}>
          <Title level={4} style={{ paddingBottom: 15 }}>Iniciar sesión</Title>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Correo electrónico"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Correo electrónico inválido',
                },
                {
                  required: true,
                  message: 'Por favor ingresa un correo electrónico',
                },
              ]}
            >
              <Input autoCapitalize="off" type="email" />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[{ required: true, message: 'Por favor ingresa una contraseña' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item style={{ marginBottom: 8 }}>
              <Button type="primary" htmlType="submit" block>
                {loading ? (
                  <LoadingOutlined />
                ) : 'Ingresar'}
              </Button>
            </Form.Item>
          </Form>
          <Button type="link" onClick={onPasswordReset}>¿Olvidaste tu contraseña?</Button>
        </FormContainer>
      )}
    </div>
  );
}

export default SignIn;
