import React, { useEffect, useState } from 'react';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import {
  Button,
  Form,
  PageHeader,
  Tabs,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  Breed, Pet, Species, User,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showErrorModal } from '../../utils/showErrorModal';
import Loading from '../../components/Loading';
import PetForm, { fetchInitialPetFormData } from './PetForm';
import useUserData from '../../hooks/useUserData';
import useWindowSize from '../../hooks/useWindowSize';
import Observations from './Observations';
import Vaccinations from './Vaccinations';
import Reminders from './Reminders';

const { TabPane } = Tabs;

interface PetDetailsState {
  addBackButton?: boolean;
}

export default function PetDetails() {
  const [windowWidth] = useWindowSize();
  const { petId } = useParams();
  const { userData } = useUserData();
  const editable = !!userData?.permissions.includes('pets.change_pet');
  const canAddVaccination = !!userData?.permissions.includes('pets.add_vaccination');
  const canAddObservation = !!userData?.permissions.includes('pets.add_observation');
  const canAddReminder = !!userData?.permissions.includes('reminders.add_reminder');

  const [pet, setPet] = useState<Pet>();
  const [users, setUsers] = useState<User[]>();
  const [species, setSpecies] = useState<Species[]>();
  const [breeds, setBreeds] = useState<Breed[]>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [selectedTab, setSelectedTab] = useState<string>('petData');
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [showAddVaccinationForm, setShowAddVaccinationForm] = useState(false);
  const [showAddObservationForm, setShowAddObservationForm] = useState(false);
  const [showAddReminderForm, setShowAddReminderForm] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const state = (location.state || {}) as PetDetailsState;

  const fetchPetDetails = () => new Promise(
    (resolve: (value: Pet) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/pets/${petId}/`,
      ).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        showErrorModal();
        reject(e);
      });
    },
  );

  useEffect(() => {
    const currentTab = searchParams.get('tab');
    if (currentTab) {
      setSelectedTab(currentTab);
    } else {
      setSelectedTab('petData');
    }
  }, [searchParams]);

  useEffect(() => {
    fetchPetDetails().then((petData) => {
      setPet(petData);
      if (editable) {
        fetchInitialPetFormData(petData.species).then(({ usersData, speciesData, breedsData }) => {
          setUsers(usersData);
          setSpecies(speciesData);
          setBreeds(breedsData);
          setLoadingData(false);
        }).catch(() => {
          showErrorModal();
          setLoadingData(false);
        });
      } else {
        setLoadingData(false);
      }
    }).catch(() => {
      showErrorModal();
      setLoadingData(false);
    });
  }, [editable]);

  const headerExtra = () => {
    if (selectedTab === 'petData') {
      return editable ? [
        ...(editing ? [
          <Button key="2" onClick={() => setEditing(false)}>
            Cancelar
          </Button>,
          <Button
            key="1"
            type="primary"
            icon={saving ? <LoadingOutlined /> : null}
            onClick={() => form.submit()}
          >
            Guardar
          </Button>,
        ] : [
          <Button
            key="1"
            type="primary"
            icon={<FontAwesomeIcon icon={faPen} />}
            onClick={() => setEditing(true)}
          >
            Editar
          </Button>,
        ]),
      ] : [];
    } if (selectedTab === 'vaccinations' && canAddVaccination) {
      return [
        <Button
          key="1"
          type="primary"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => setShowAddVaccinationForm(true)}
        >
          Registrar vacunación
        </Button>,
      ];
    } if (selectedTab === 'observations' && canAddObservation) {
      return [
        <Button
          key="1"
          type="primary"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => setShowAddObservationForm(true)}
        >
          Agregar a la ficha
        </Button>,
      ];
    } if (selectedTab === 'reminders' && canAddReminder) {
      return [
        <Button
          key="1"
          type="primary"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => setShowAddReminderForm(true)}
        >
          Agregar recordatorio
        </Button>,
      ];
    }
    return [];
  };

  const onCloseModal = () => {
    fetchPetDetails().then((petData) => {
      setPet(petData);
    }).catch(() => {
      showErrorModal();
    });
  };

  return loadingData || !pet ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title={pet.name}
        avatar={{ src: pet.species.image_url }}
        className="site-page-header"
        onBack={state.addBackButton ? () => navigate(-1) : undefined}
        extra={headerExtra()}
      />
      <Tabs
        activeKey={selectedTab}
        onChange={(activeKey: string) => {
          setSelectedTab(activeKey);
          setSearchParams({ ...searchParams, tab: activeKey }, { state });
        }}
        tabPosition={windowWidth && windowWidth < 650 ? 'top' : 'left'}
      >
        <TabPane tab="Datos de la mascota" key="petData">
          <PetForm
            form={form}
            pet={pet}
            species={species}
            initialOwners={users}
            initialBreeds={breeds}
            editing={editing}
            setEditing={setEditing}
            setSaving={setSaving}
            onComplete={setPet}
          />
        </TabPane>
        <TabPane tab="Ficha de la mascota" key="observations">
          <Observations
            pet={pet}
            showAddObservationForm={showAddObservationForm}
            setShowAddObservationForm={setShowAddObservationForm}
          />
        </TabPane>
        <TabPane tab="Vacunaciones" key="vaccinations">
          <Vaccinations
            pet={pet}
            onCloseModal={onCloseModal}
            showAddVaccinationForm={showAddVaccinationForm}
            setShowAddVaccinationForm={setShowAddVaccinationForm}
            canAddVaccination={canAddVaccination}
          />
        </TabPane>
        <TabPane tab="Recordatorios" key="reminders">
          <Reminders
            pet={pet}
            onCloseModal={onCloseModal}
            showAddReminderForm={showAddReminderForm}
            setShowAddReminderForm={setShowAddReminderForm}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
