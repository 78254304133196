import React, { useState } from 'react';
import {
  Button,
  Form,
  PageHeader,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import OwnerForm from './OwnerForm';

export default function AddOwner() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  return (
    <div className="site-layout-content">
      <PageHeader
        title="Agregar dueño o tutor"
        className="site-page-header"
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key="1"
            type="primary"
            icon={saving ? <LoadingOutlined /> : null}
            onClick={() => form.submit()}
          >
            Guardar
          </Button>,
        ]}
      />
      <OwnerForm
        form={form}
        setSaving={setSaving}
        onComplete={() => navigate('/owners', { replace: true })}
      />
    </div>
  );
}
