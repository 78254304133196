import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Avatar, Button, Form, List, PageHeader, Tabs,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../components/Loading';
import { User } from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showErrorModal } from '../../utils/showErrorModal';
import useWindowSize from '../../hooks/useWindowSize';
import useUserData from '../../hooks/useUserData';
import OwnerForm from './OwnerForm';

const { TabPane } = Tabs;

export default function OwnerDetails() {
  const [windowWidth] = useWindowSize();
  const navigate = useNavigate();
  const { ownerId } = useParams();
  const { userData } = useUserData();
  const [owner, setOwner] = useState<User>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [selectedTab, setSelectedTab] = useState<string>('personalData');
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/${ownerId}/`,
    ).then((response) => {
      const petData = response.data;
      setOwner(petData);
      setLoadingData(false);
    }).catch(() => {
      showErrorModal();
      setLoadingData(false);
    });
  }, []);

  return loadingData || !owner ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title={owner.full_name}
        className="site-page-header"
        onBack={() => navigate(-1)}
        extra={selectedTab === 'personalData' && userData?.permissions.includes('pets.add_pet') ? [
          ...(editing ? [
            <Button key="2" onClick={() => setEditing(false)}>
              Cancelar
            </Button>,
            <Button
              key="1"
              type="primary"
              icon={saving ? <LoadingOutlined /> : null}
              onClick={() => form.submit()}
            >
              Guardar
            </Button>,
          ] : [
            <Button
              key="1"
              type="primary"
              icon={<FontAwesomeIcon icon={faPen} />}
              onClick={() => setEditing(true)}
            >
              Editar
            </Button>,
          ]),
        ] : []}
      />
      <Tabs
        defaultActiveKey={selectedTab}
        onChange={setSelectedTab}
        tabPosition={windowWidth && windowWidth < 650 ? 'top' : 'left'}
      >
        <TabPane tab="Datos personales" key="personalData">
          <OwnerForm
            form={form}
            user={owner}
            editing={editing}
            setEditing={setEditing}
            setSaving={setSaving}
            onComplete={setOwner}
          />
        </TabPane>
        <TabPane tab="Mascotas" key="pets">
          <List
            itemLayout="horizontal"
            dataSource={owner.pets}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar size="large" src={item.species.image_url} />}
                  title={(
                    <Link
                      key={item.id}
                      to={`/pets/${item.id}`}
                      state={{ addBackButton: true }}
                    >
                      {item.name}
                    </Link>
                  )}
                  description={
                    `${item.birth_date ? item.age : ''}
                    ${item.sex ? ` · ${item.sex}` : ''}
                    ${item.breed ? ` · ${item.breed.name}` : ''}`
                  }
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
