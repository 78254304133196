import React, { useEffect, useState } from 'react';
import {
  Button, Col, Divider, Layout, Menu, Row, Space, Dropdown,
} from 'antd';
import {
  CloseOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { useLocation, useNavigate } from 'react-router-dom';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import logo from '../assets/logo.png';
import useWindowSize from '../hooks/useWindowSize';
import { User } from '../types/base';

const { Header, Content, Sider } = Layout;

const Logo = styled.div`
  float: left;
  width: 180px;
  height: calc(74px - 15px - 15px);
  margin: 15px 24px 15px 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${logo});
  background-position-x: center;
  cursor: pointer;

  @media only screen and (max-device-width : 650px) {
    width: 120px;
    height: calc(64px - 12px - 12px);
    margin: 12px 10px 12px 10px;
  }
`;

type UserMenuProps = {
  onClickMenu: MenuClickEventHandler
};

function UserMenu({ onClickMenu }: UserMenuProps) {
  const userMenu = (
    <Menu
      items={[
        {
          label: 'Perfil', key: 'profile',
        },
        {
          label: 'Mis pedidos', key: 'orders',
        },
        {
          label: 'Cerrar sesión', icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />, key: 'logout', danger: true,
        },
      ]}
      onClick={onClickMenu}
    />
  );

  return (
    <Dropdown overlay={userMenu}>
      <Button
        type="primary"
        shape="circle"
        icon={<UserOutlined style={{ color: '#ffffff' }} />}
        style={{
          height: 35,
          width: 35,
          borderRadius: '50%',
          backgroundColor: 'var(--secondary-accent-color)',
        }}
      />
    </Dropdown>
  );
}

type MenuLayoutProps = {
  isLoggedIn?: boolean;
  userData?: User;
  logout?: () => void;
  children: JSX.Element,
};

export default function MenuLayout({
  isLoggedIn = true,
  userData,
  logout,
  children,
}: MenuLayoutProps) {
  // const [defaultSelectedMenuItem, setDefaultSelectedMenuItem] = useState([]);
  const navigate = useNavigate();
  const [windowWidth] = useWindowSize();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);
  const location = useLocation();
  const menuItems = isLoggedIn ? [
    ...(userData?.permissions.includes('services.view_appointment')) ? [
      { label: 'Agenda', key: 'appointments', path: '/appointments' },
    ] : [],
    ...userData?.permissions.includes('services.view_transportation') ? [
      { label: 'Transporte', key: 'transportation', path: '/transportation' },
    ] : [],
    ...userData?.permissions.includes('pets.view_pet') ? [
      { label: 'Mascotas', key: 'pets', path: '/pets' },
    ] : [],
    ...userData?.permissions.includes('users.view_user') ? [
      { label: 'Dueños', key: 'owners', path: '/owners' },
    ] : [],
  ] : [];

  useEffect(() => {
    if (menuItems.length > 0) {
      const newSelectedItem = menuItems.find(
        (item) => location.pathname.startsWith(item.path),
      );
      if (newSelectedItem) {
        setSelectedMenuKeys([newSelectedItem.key]);
      } else {
        setSelectedMenuKeys([]);
      }
    }
  }, [location]);

  const onClickMenu: MenuClickEventHandler = ({ key }) => {
    if (key === 'logout' && logout) {
      logout();
    } else {
      navigate(key);
      setSelectedMenuKeys([key]);
    }
  };

  const defaultHeader = (
    <Row justify="space-between" style={{ marginRight: 14 }}>
      <Col>
        <Menu
          mode="horizontal"
          selectedKeys={selectedMenuKeys}
          items={menuItems}
          onClick={onClickMenu}
          style={{ minWidth: 410 }}
        />
      </Col>
      {isLoggedIn ? (
        <Col>
          <UserMenu onClickMenu={onClickMenu} />
        </Col>
      ) : (
        <Col>
          <Button
            type="primary"
            onClick={() => navigate('signin')}
            style={{ width: 120, height: 36 }}
          >
            <FontAwesomeIcon icon={faUnlockKeyhole} style={{ paddingRight: 5 }} />
            Ingresar
          </Button>
        </Col>
      )}
    </Row>
  );

  const smallScreenHeader = (
    <Row justify="end">
      <Space>
        {isLoggedIn && (
          <UserMenu onClickMenu={onClickMenu} />
        )}
        <Button
          type="link"
          size="small"
          onClick={() => setCollapsed(false)}
        >
          <MenuOutlined />
        </Button>
      </Space>
    </Row>
  );

  const smallScreenMenu = (
    <>
      <Header>
        <Row justify="end">
          <Col>
            <Button type="link" onClick={() => setCollapsed(true)}>
              <CloseOutlined />
            </Button>
          </Col>
        </Row>
      </Header>

      <Menu
        theme="light"
        mode="inline"
        selectedKeys={selectedMenuKeys}
        items={[
          ...menuItems,
          ...(isLoggedIn ? [{
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            label: 'Cerrar sesión',
            key: 'logout',
          }] : []),
        ]}
        onClick={(props) => {
          onClickMenu(props);
          setCollapsed(true);
        }}
      />

      <Divider style={{ margin: 0 }} />

      {!isLoggedIn && (
        <Space style={{ marginTop: 10, marginBottom: 10 }}>
          <Button
            type="primary"
            onClick={() => {
              navigate('signin');
              setCollapsed(true);
            }}
            style={{ width: 120, height: 36 }}
          >
            <FontAwesomeIcon icon={faUnlockKeyhole} style={{ paddingRight: 5 }} />
            Ingresar
          </Button>
        </Space>
      )}
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Header>
          <Logo onClick={() => navigate('/')} />
          {windowWidth && windowWidth > 710 ? defaultHeader : smallScreenHeader }
        </Header>
        <Content>
          {children}
        </Content>
      </Layout>

      {windowWidth && windowWidth < 710 && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={280}
          collapsedWidth={0}
          style={{
            position: 'absolute', height: '100vh', right: 0,
          }}
        >
          {smallScreenMenu}
        </Sider>
      )}
    </Layout>
  );
}
