import React, { useEffect, useState } from 'react';
import {
  AutoComplete, Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

import {
  Breed, Pet, Species, User,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showErrorModal } from '../../utils/showErrorModal';
import { BreedFormValues, OptionValue, PetFormValues } from '../../types/forms';
import { renderDate, renderUser } from '../../utils/tables';
import useUserData from '../../hooks/useUserData';
import OwnerForm from '../owners/OwnerForm';

const { Option } = Select;
const { Text } = Typography;

interface FetchPetFormData {
  usersData: User[];
  speciesData: Species[];
  breedsData: Breed[];
}

export const fetchInitialPetFormData = (selectedSpecies?: Species) => new Promise(
  (resolve: (value: FetchPetFormData) => void, reject) => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/`,
      {
        params: {
          groups__name: 'owners',
          offset: 0,
          limit: 10,
        },
      },
    ).then((usersResponse) => {
      const usersData = usersResponse.data.results;
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/pets/species/`,
      ).then((speciesResponse) => {
        const speciesData = speciesResponse.data;
        axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/pets/breeds/`,
          {
            params: {
              species__id: selectedSpecies?.id || undefined,
              offset: 0,
              limit: 10,
            },
          },
        ).then((breedsResponse) => {
          const breedsData = breedsResponse.data.results;
          resolve({ usersData, speciesData, breedsData });
        }).catch((e) => {
          reject(e);
        });
      }).catch((e) => {
        reject(e);
      });
    }).catch((e) => {
      reject(e);
    });
  },
);

const fetchSpecies = () => new Promise(
  (resolve: (value: Species[]) => void, reject) => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/pets/species/`,
    ).then((response) => {
      resolve(response.data);
    }).catch((e) => {
      showErrorModal();
      reject(e);
    });
  },
);

export interface AddMoreOptionsButtonProps {
  addOption: () => void;
}

function AddMoreOptionsButton({ addOption }: AddMoreOptionsButtonProps) {
  return (
    <Button
      size="small"
      onClick={addOption}
      style={{ whiteSpace: 'nowrap', verticalAlign: -1 }}
      icon={<FontAwesomeIcon icon={faPlus} style={{ color: 'rgba(0, 0, 0, 0.45)', padding: 0 }} />}
    />
  );
}

export interface AddOwnerModalFormProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onComplete: (newOwner: User) => void;
}

function AddOwnerModalForm({
  showModal, setShowModal, onComplete,
}: AddOwnerModalFormProps) {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  const onAddedUser = (newOwner: User) => {
    setShowModal(false);
    form.resetFields();
    onComplete(newOwner);
  };

  return (
    <Modal
      title="Agregar dueño o tutor"
      visible={showModal}
      onOk={() => form.submit()}
      confirmLoading={saving}
      onCancel={() => setShowModal(false)}
    >
      <OwnerForm
        form={form}
        setSaving={setSaving}
        onComplete={onAddedUser}
      />
    </Modal>
  );
}

export interface AddBreedModalFormProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onComplete: (newBreed: Breed) => void;
  initialValues?: BreedFormValues;
  species?: Species[];
}

function AddBreedModalForm({
  showModal, setShowModal, onComplete, initialValues, species = [],
}: AddBreedModalFormProps) {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  const onAddBreed = (values: BreedFormValues) => {
    setSaving(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/pets/breeds/`,
      {
        ...values,
        species: values.species?.value,
      },
    ).then((response) => {
      const newBreed = response.data;
      setSaving(false);
      form.resetFields();
      onComplete(newBreed);
    }).catch(() => {
      message.error(
        'Ocurrió un error al agregar la nueva raza, por favor intenta nuevamente',
      );
      setSaving(false);
    });
  };

  return (
    <Modal
      title="Agregar nueva raza"
      visible={showModal}
      onOk={() => form.submit()}
      confirmLoading={saving}
      onCancel={() => setShowModal(false)}
    >
      <Form
        form={form}
        onFinish={onAddBreed}
        autoComplete="off"
        size="small"
        initialValues={initialValues}
      >
        <Row gutter={[8, 32]} align="top">
          <Col span={12}>
            <Text strong>Nombre:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa un nombre',
                },
              ]}
            >
              <Input placeholder="Ingresa un nombre" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Text strong>Especie:</Text>
          </Col>
          <Col span={12}>
            <Form.Item
              name="species"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Por favor selecciona una especie',
                },
              ]}
            >
              <Select
                labelInValue
                placeholder="Selecciona una especie"
                style={{ width: '100%' }}
              >
                {species?.map((speciesValue) => (
                  <Option key={speciesValue.id}>{speciesValue.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export interface PetFormProps {
  form: FormInstance<PetFormValues>;
  pet?: Pet | null;
  species?: Species[];
  initialOwners?: User[];
  initialBreeds?: Breed[];
  editing?: boolean;
  setEditing?: (saving: boolean) => void;
  setSaving: (saving: boolean) => void;
  onComplete: (owner: Pet) => void;
}

export interface AutoCompleteOption {
  key: number | string;
  value: string;
  label?: React.ReactNode;
}

export default function PetForm({
  form,
  pet = null,
  species = [],
  initialOwners = [],
  initialBreeds = [],
  editing = true,
  setEditing = () => {},
  setSaving,
  onComplete,
}: PetFormProps) {
  const { userData } = useUserData();
  const [owners, setOwners] = useState<AutoCompleteOption[]>([]);
  const [selectedSpecies, setSelectedSpecies] = useState<OptionValue | null>(
    pet ? { value: `${pet.species.id}`, label: `${pet.species.name}` } : null,
  );
  const [filteredBreeds, setFilteredBreeds] = useState<AutoCompleteOption[]>([]);
  const [selectedBreed, setSelectedBreed] = useState<AutoCompleteOption | null>(
    pet && pet.breed ? { key: pet.breed.id, value: `${pet.breed.name}` } : null,
  );
  const [showAddBreedForm, setShowAddBreedForm] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState<AutoCompleteOption | null>(pet ? {
    key: pet.owner.id, value: pet.owner.full_name, label: renderUser(pet.owner),
  } : null);
  const [showAddOwnerForm, setShowAddOwnerForm] = useState(false);
  const [loadingOwnerSearch, setLoadingOwnerSearch] = useState(false);
  const [loadingBreedSearch, setLoadingBreedSearch] = useState(false);

  useEffect(() => {
    setOwners(initialOwners.map((owner: User) => (
      {
        key: owner.id,
        value: owner.full_name,
        label: renderUser(owner),
      }
    )));
    setFilteredBreeds(initialBreeds.map((breed: Breed) => (
      {
        key: breed.id,
        value: breed.name,
      }
    )));
  }, [initialOwners, initialBreeds]);

  const onSearchOwner = (searchText: string) => {
    setLoadingOwnerSearch(true);
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/`,
      {
        params: {
          groups__name: 'owners',
          search: searchText,
          offset: 0,
          limit: 10,
        },
      },
    ).then((response) => {
      setOwners(response.data.results.map((owner: User) => (
        {
          key: owner.id,
          value: owner.full_name,
          label: renderUser(owner),
        }
      )));
      setLoadingOwnerSearch(false);
    }).catch(() => {
      setLoadingOwnerSearch(false);
    });
  };

  const onSelectOwner = (value: any, option: AutoCompleteOption) => {
    setSelectedOwner(option);
  };

  const onSearchBreed = (searchText: string) => {
    setLoadingBreedSearch(true);
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/pets/breeds/`,
      {
        params: {
          species__id: selectedSpecies?.value || undefined,
          search: searchText,
          offset: 0,
          limit: 10,
        },
      },
    ).then((response) => {
      setFilteredBreeds(response.data.results.map((breed: Breed) => (
        {
          key: breed.id,
          value: breed.name,
        }
      )));
      setLoadingBreedSearch(false);
    }).catch(() => {
      setLoadingBreedSearch(false);
    });
  };

  const onSelectBreed = (value: any, option: AutoCompleteOption) => {
    setSelectedBreed(option);
    if (!selectedSpecies && species) {
      const newSelectedSpecies: Species | undefined = species.find((speciesValue) => (
        speciesValue.breeds.find((breedValue) => (
          breedValue.id === parseInt(value.value, 10)
        )) !== undefined
      ));
      if (newSelectedSpecies) {
        setSelectedSpecies({
          value: `${newSelectedSpecies.id}`,
          label: `${newSelectedSpecies.name}`,
        });
      }
    }
  };

  const onChangeSpecies = (value: OptionValue) => {
    setSelectedSpecies(value);
    if (species) {
      setSelectedBreed(null);
      setFilteredBreeds([]);
    }
  };

  const onCreate = (values: PetFormValues) => {
    if (selectedSpecies && selectedOwner) {
      setSaving(true);
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/pets/`,
        {
          ...values,
          species: selectedSpecies.value,
          breed: selectedBreed?.key || null,
          sex: values.sex || null,
          birth_date: values.birth_date?.format('YYYY-MM-DD') || null,
          owner: selectedOwner.key,
        },
      ).then((response) => {
        setSaving(false);
        onComplete(response.data);
      }).catch(() => {
        message.error(
          'Ocurrió un error al agregar la mascota, por favor intenta nuevamente',
        ).then(() => setSaving(false));
        setSaving(false);
      });
    } else {
      if (!selectedSpecies) {
        message.error(
          'Debes seleccionar una especie',
        );
      }
      if (!selectedOwner) {
        message.error(
          'Debes seleccionar un dueño o tutor',
        );
      }
    }
  };

  const onUpdate = (values: PetFormValues) => {
    if (pet) {
      if (selectedSpecies && selectedOwner) {
        setSaving(true);
        axiosInstance.put(
          `${process.env.REACT_APP_API_URL}/pets/${pet.id}/`,
          {
            ...values,
            id: pet.id,
            species: selectedSpecies?.value,
            breed: selectedBreed?.key || null,
            sex: values.sex || null,
            birth_date: values.birth_date?.format('YYYY-MM-DD') || null,
            owner: selectedOwner?.key,
          },
        ).then((response) => {
          setSaving(false);
          if (setEditing) {
            setEditing(false);
          }
          onComplete(response.data);
        }).catch(() => {
          message.error(
            'Ocurrió un error al actualizar los datos, por favor intenta nuevamente',
          ).then(() => setSaving(false));
        });
      } else {
        if (!selectedSpecies) {
          message.error(
            'Debes seleccionar una especie',
          );
        }
        if (!selectedOwner) {
          message.error(
            'Debes seleccionar un dueño o tutor',
          );
        }
      }
    }
  };

  const onCompleteAddOwner = (newOwner: User) => {
    setSelectedOwner({
      key: newOwner.id,
      value: newOwner.full_name,
      label: renderUser(newOwner),
    });
  };

  const onCompleteAddBreed = (newBreed: Breed) => {
    fetchSpecies().then((newSpecies: Species[]) => {
      setSelectedBreed({ key: newBreed.id, value: newBreed.name });
      const newSelectedSpecies: Species | undefined = newSpecies.find((speciesValue) => (
        speciesValue.breeds.find((breedValue) => (
          breedValue.id === newBreed.id
        )) !== undefined
      ));
      if (newSelectedSpecies) {
        setSelectedSpecies({
          value: `${newSelectedSpecies.id}`,
          label: `${newSelectedSpecies.name}`,
        });
      }
    });
    setShowAddBreedForm(false);
  };

  const ownerName = (
    userData?.permissions.includes('users.view_user') ? (
      <Link
        style={{ margin: '0' }}
        to={`/owners/${pet?.owner.id}`}
        key={pet?.owner.id}
      >
        {pet?.owner.full_name}
      </Link>
    ) : (
      <span className="ant-form-text">{pet?.owner.full_name}</span>
    )
  );

  return (
    <>
      <Form
        form={form}
        initialValues={pet ? {
          name: pet.name,
          sex: pet.sex,
          birth_date: pet.birth_date ? moment(pet.birth_date) : null,
          microchip_number: pet.microchip_number,
        } : {}}
        onFinish={pet ? onUpdate : onCreate}
        autoComplete="off"
        size="small"
      >
        <div style={{ maxWidth: 500, paddingTop: 20, paddingBottom: 20 }}>
          <Row gutter={[8, 32]} align="top">
            <Col span={12}>
              <Text strong>Dueño o Tutor:</Text>
            </Col>
            <Col span={12}>
              {editing ? (
                <div>
                  <AutoComplete
                    defaultValue={selectedOwner?.value}
                    options={owners}
                    onSelect={onSelectOwner}
                    onSearch={onSearchOwner}
                    style={{ width: 'calc(100% - 24px)' }}
                  >
                    <Input.Search
                      placeholder="Busca un dueño o tutor"
                      loading={loadingOwnerSearch}
                    />
                  </AutoComplete>
                  <AddMoreOptionsButton addOption={() => setShowAddOwnerForm(true)} />
                </div>
              ) : ownerName}
            </Col>

            <Col span={12}>
              <Text strong>Nombre:</Text>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa un nombre',
                  },
                ]}
              >
                {editing ? (
                  <Input placeholder="Ingresa un nombre" />
                ) : <span className="ant-form-text">{pet?.name}</span>}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Text strong>Especie:</Text>
            </Col>
            <Col span={12}>
              {editing ? (
                <Select
                  labelInValue
                  onChange={onChangeSpecies}
                  value={selectedSpecies}
                  placeholder="Selecciona una especie"
                  style={{ width: '100%' }}
                >
                  {species?.map((speciesValue) => (
                    <Option key={speciesValue.id}>{speciesValue.name}</Option>
                  ))}
                </Select>
              ) : <span className="ant-form-text">{pet?.species.name}</span>}
            </Col>

            <Col span={12}>
              <Text strong>Raza:</Text>
            </Col>
            <Col span={12}>
              {editing ? (
                <div>
                  <AutoComplete
                    defaultValue={selectedBreed?.value}
                    options={filteredBreeds}
                    onSearch={onSearchBreed}
                    onSelect={onSelectBreed}
                    style={{ width: 'calc(100% - 24px)' }}
                  >
                    <Input.Search
                      placeholder="Busca una raza"
                      loading={loadingBreedSearch}
                    />
                  </AutoComplete>
                  <AddMoreOptionsButton addOption={() => setShowAddBreedForm(true)} />
                </div>
              ) : <span className="ant-form-text">{pet?.breed?.name || '-'}</span>}
            </Col>

            <Col span={12}>
              <Text strong>Sexo:</Text>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sex"
                style={{ margin: 0 }}
              >
                {editing ? (
                  <Select allowClear placeholder="Selecciona un sexo" style={{ width: '100%' }}>
                    <Option value="hembra">Hembra</Option>
                    <Option value="macho">Macho</Option>
                  </Select>
                ) : <span className="ant-form-text">{pet?.sex || '-'}</span>}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Text strong>Fecha de nacimiento:</Text>
            </Col>
            <Col span={12}>
              <Form.Item
                name="birth_date"
                style={{ margin: 0 }}
              >
                {editing ? (
                  <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                ) : (
                  <span className="ant-form-text">
                    {pet?.birth_date ? `${renderDate(pet.birth_date)} (${pet?.age})` : '-'}
                  </span>
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Text strong>Numero de chip</Text>
            </Col>
            <Col span={12}>
              <Form.Item
                name="microchip_number"
                style={{ margin: 0 }}
              >
                {editing ? (
                  <Input placeholder="Ingresa el número de chip" />
                ) : (
                  <span className="ant-form-text">{pet?.microchip_number || '-'}</span>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>

      <AddOwnerModalForm
        showModal={showAddOwnerForm}
        setShowModal={setShowAddOwnerForm}
        onComplete={onCompleteAddOwner}
      />

      <AddBreedModalForm
        showModal={showAddBreedForm}
        setShowModal={setShowAddBreedForm}
        species={species}
        onComplete={onCompleteAddBreed}
        initialValues={{
          name: '',
          species: selectedSpecies || undefined,
        }}
      />
    </>
  );
}
