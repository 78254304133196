import React, { useEffect, useState } from 'react';
import {
  Button, Col, Input, PageHeader, Row, Table,
} from 'antd';
import type { TablePaginationConfig } from 'antd/lib/table';
import type { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import {
  Pet, User,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import {
  defaultPagination,
  defaultParams,
  handleTableChange,
  renderDate,
} from '../../utils/tables';
import Loading from '../../components/Loading';
import { showErrorModal } from '../../utils/showErrorModal';
import useUserData from '../../hooks/useUserData';
import { formatRut } from '../../utils/rut';

const { Search } = Input;

interface FetchOwnersParams {
  limit?: number;
  offset?: number;
  ordering?: string;
  search? :string;
}

export default function Owners() {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const [owners, setOwners] = useState<User[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [fetchParams, setFetchParams] = useState<FetchOwnersParams>(defaultParams);

  const fetchOwnersList = (
    newPagination: TablePaginationConfig,
    params: FetchOwnersParams = {},
  ) => new Promise(
    (resolve: (value: Pet[]) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/users/`,
        {
          params: {
            ...params,
            groups__name: 'owners',
          },
        },
      ).then((response) => {
        setOwners(response.data.results);
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        resolve(response.data.results);
      }).catch((e) => {
        showErrorModal();
        reject(e);
      });
    },
  );

  useEffect(() => {
    fetchOwnersList(pagination, fetchParams).then(() => setLoadingData(false));
  }, []);

  const onTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: (SorterResult<User> | SorterResult<User>[]),
  ) => {
    handleTableChange(
      newPagination,
      filters,
      sorter,
      (pagConfig, params) => {
        fetchOwnersList(pagConfig, params).then(() => setLoadingData(false));
      },
    );
  };

  const onChangeSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    setLoadingSearch(true);
    fetchOwnersList(pagination, {
      ...fetchParams,
      search: (e.target as HTMLInputElement).value,
    }).then(() => setLoadingSearch(false));
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'full_name',
      key: 'first_name',
      sorter: true,
      render: (_value: string, record: User) => (
        <Link
          style={{ display: 'block', margin: '1rem 0' }}
          to={`/owners/${record.id}`}
          key={record.id}
        >
          {record.full_name}
        </Link>
      ),
    },
    {
      title: 'RUT',
      dataIndex: 'rut',
      key: 'rut',
      sorter: true,
      render: (value: string) => (
        value ? formatRut(value) : ''
      ),
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Teléfono',
      dataIndex: 'mobile',
      key: 'mobile',
      sorter: true,
    },
    {
      title: 'Fecha de incorporación',
      dataIndex: 'date_joined',
      key: 'date_joined',
      render: renderDate,
      sorter: true,
    },
  ];

  return loadingData ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title="Dueños y tutores"
        className="site-page-header"
        extra={userData?.permissions.includes('users.add_user') ? [
          <Button
            key="1"
            type="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => navigate('/owners/add')}
          >
            Agregar
          </Button>,
        ] : []}
      />

      <Row justify="end" style={{ marginBottom: 20 }}>
        <Col xs={24} sm={10} md={6} lg={6} xl={4}>
          <Search
            placeholder="Buscar"
            loading={loadingSearch}
            onChange={onChangeSearchValue}
          />
        </Col>
      </Row>

      <Table
        rowKey="id"
        dataSource={owners}
        columns={columns}
        scroll={{ x: 900 }}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
}
