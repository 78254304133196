import moment from 'moment';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import _ from 'lodash';
import { User } from '../types/base';
import { formatRut } from './rut';

const dateFormat = 'DD/MM/YYYY';
const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';

export const renderDateTimeWithFormat = (value: string, format: string = dateFormat) => {
  moment.locale('es');
  const date = moment(value);
  return date.format(format);
};

export const renderDate = (value: string | null) => {
  if (!value) return null;
  return renderDateTimeWithFormat(value, dateFormat);
};

export const renderDateTime = (value: string | null) => {
  if (!value) return null;
  return renderDateTimeWithFormat(value, dateTimeFormat);
};

export const renderTime = (value: string | null) => {
  if (!value) return null;
  return renderDateTimeWithFormat(value, 'HH:mm');
};

export function renderNumericalValue(value: number) {
  return Number(value).toLocaleString('es-CL', { maximumFractionDigits: 2 });
}

export const sortDates = (valueA: string, valueB: string) => {
  const dateA = moment(valueA);
  const dateB = moment(valueB);
  if (dateA.isBefore(dateB)) {
    return -1;
  }
  if (dateA.isAfter(dateB)) {
    return 1;
  }
  return 0;
};

export const renderUser = (record: User | undefined) => {
  if (record) {
    const rut = record.rut ? ` (${formatRut(record.rut)})` : '';
    return `${record.full_name || record.email}${rut}`;
  }
  return '';
};

export const defaultPagination = {
  current: 1,
  pageSize: 10,
};

export const defaultParams = {
  limit: 10,
  offset: 0,
};

interface FetchResultsParams {
  limit?: number;
  offset?: number;
  ordering?: string;
  search? :string;
}

export function handleTableChange<T>(
  newPagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: (SorterResult<T> | SorterResult<T>[]),
  fetchResults: (pagination: TablePaginationConfig, params: FetchResultsParams) => void,
) {
  const pageSize = newPagination.pageSize || defaultPagination.pageSize;
  const current = newPagination.current || defaultPagination.current;

  const sortField = Array.isArray(sorter) ? sorter[0].columnKey : sorter.columnKey;
  const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
  const ordering = sortOrder ? `${sortOrder === 'descend' ? '-' : ''}${sortField}` : undefined;

  const filterParams = filters ? _.fromPairs(
    Object.keys(filters).filter(
      (value: string) => filters[value] !== null,
    ).map(
      (value: string) => [`${value}__in`, filters[value]!.join(',')],
    ),
  ) : {};

  fetchResults(newPagination, {
    limit: pageSize,
    offset: (current - 1) * pageSize,
    ordering,
    ...filterParams,
  });
}
