import React from 'react';
import { Token } from '../../types/base';
import PasswordForm from './PasswordForm';

type VerifyUserProps = {
  setToken?: (userToken: Token) => void;
};

function VerifyUser(props: VerifyUserProps) {
  return (
    <PasswordForm isInitialPassword {...props} />
  );
}

export default VerifyUser;
