import React, { useState } from 'react';
import {
  Button, Col, Form, Input, message, PageHeader, Row, Typography,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import useUserData from '../../hooks/useUserData';
import { axiosInstance } from '../../utils/axios-instance';
import { formatRut, isValidRut } from '../../utils/rut';
import { RutInput } from '../../components/RutInput';
import { isValidPhoneNumber } from '../../utils/forms';
import { UserFormValues } from '../../types/forms';

const { Text } = Typography;

interface ProfileUpdateResponseData {
  rut: string[];
  email: string[];
}

export default function Profile() {
  const { userData, setUserData } = useUserData();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const onUpdate = (values: UserFormValues) => {
    setSaving(true);
    axiosInstance.put(
      `${process.env.REACT_APP_API_URL}/users/me/`,
      {
        ...values,
        rut: values.rut?.replaceAll('.', ''),
      },
    ).then((response) => {
      setSaving(false);
      setEditing(false);
      setUserData(response.data);
    }).catch((e) => {
      const errorMessages = (e.response?.data as ProfileUpdateResponseData);
      if ((errorMessages.email || []).join(', ').includes('user with this email already exists')) {
        message.error('El correo ingresado ya está registrado');
      } else if ((errorMessages.rut || []).join(', ').includes('user with this rut already exists')) {
        message.error('El RUT ingresado ya está registrado');
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente.');
      }
      setSaving(false);
    });
  };

  return (
    <div className="site-layout-content">
      <PageHeader
        title="Perfil"
        className="site-page-header"
        extra={[
          ...(editing ? [
            <Button key="2" onClick={() => setEditing(false)}>
              Cancelar
            </Button>,
            <Button
              key="1"
              type="primary"
              icon={saving ? <LoadingOutlined /> : null}
              onClick={() => form.submit()}
            >
              Guardar
            </Button>,
          ] : [
            <Button
              key="1"
              type="primary"
              icon={<FontAwesomeIcon icon={faPen} />}
              onClick={() => setEditing(true)}
            >
              Editar
            </Button>,
          ]),
        ]}
      />
      <Form
        form={form}
        initialValues={userData}
        onFinish={onUpdate}
        autoComplete="off"
        size="small"
      >
        <div style={{ maxWidth: 500, paddingTop: 20, paddingBottom: 20 }}>
          <Row gutter={[8, 32]} align="middle">
            <Col span={10}>
              <Text strong>Nombre:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="first_name"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa un nombre',
                  },
                ]}
              >
                {editing ? (
                  <Input placeholder="Ingresa un apellido" />
                ) : (
                  <span className="ant-form-text">{userData?.first_name}</span>
                )}
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Apellidos:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="last_name"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa un apellido',
                  },
                ]}
              >
                {editing ? (
                  <Input placeholder="Ingresa un apellido" />
                ) : (
                  <span className="ant-form-text">{userData?.last_name}</span>
                )}
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Corro electrónico:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="email"
                style={{ margin: 0 }}
                rules={[
                  {
                    type: 'email',
                    message: 'Correo electrónico inválido',
                  },
                  {
                    required: true,
                    message: 'Por favor ingresa un correo electrónico',
                  },
                ]}
              >
                {editing ? (
                  <Input placeholder="Ingresa un correo" />
                ) : (
                  <span className="ant-form-text">{userData?.email}</span>
                )}
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Teléfono:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="mobile"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa un teléfono',
                  },
                  () => ({
                    validator(_, value) {
                      if (!isValidPhoneNumber(value)) {
                        return Promise.reject(new Error('El teléfono ingresado es inválido'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {editing ? (
                  <Input placeholder="Ingresa un teléfono" />
                ) : (
                  <span className="ant-form-text">{userData?.mobile || '-'}</span>
                )}
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>RUT:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="rut"
                style={{ margin: 0 }}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!isValidRut(value)) {
                        return Promise.reject(new Error('El RUT ingresado es inválido'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {editing ? (
                  <RutInput placeholder="Ingresa un RUT" />
                ) : (
                  <span className="ant-form-text">{userData?.rut ? formatRut(userData.rut) : '-'}</span>
                )}
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Dirección:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="address"
                style={{ margin: 0 }}
              >
                {editing ? (
                  <Input placeholder="Ingresa una dirección" />
                ) : (
                  <span className="ant-form-text">{userData?.address || '-'}</span>
                )}
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Comuna:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="commune"
                style={{ margin: 0 }}
              >
                {editing ? (
                  <Input placeholder="Ingresa una comuna" />
                ) : (
                  <span className="ant-form-text">{userData?.commune || '-'}</span>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}
