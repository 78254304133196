import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Appointment } from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showErrorModal } from '../../utils/showErrorModal';
import Loading from '../../components/Loading';
import useUserData from '../../hooks/useUserData';

const localizer = momentLocalizer(moment);

export const messages = {
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
  week: 'Semana',
  work_week: 'Semana de trabajo',
  day: 'Día',
  month: 'Mes',
  previous: 'Anterior',
  next: 'Siguiente',
  today: 'Hoy',
  agenda: 'Agenda',
  showMore: (total: number) => `+${total} más`,
  noEventsInRange: 'No hay eventos en este rango.',
};

export default function Appointments() {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const defaultView = userData.groups.includes('service_providers')
  || userData.groups.includes('administrators')
    ? 'week' : 'month';
  const [range, setRange] = useState<{start: Date; end: Date}>();

  useEffect(() => {
    if (defaultView === 'week') {
      setRange({
        start: moment().startOf('isoWeek').startOf('day').toDate(),
        end: moment().endOf('isoWeek').endOf('day').toDate(),
      });
    } else {
      setRange({
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('month').endOf('day').toDate(),
      });
    }
  }, [defaultView]);

  useEffect(() => {
    if (range) {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/services/appointments/?start__gte=${range.start.toISOString()}&end__lte=${range.end.toISOString()}`,
      ).then((response) => {
        setAppointments(response.data);
        setLoadingData(false);
      }).catch(() => {
        showErrorModal();
        setLoadingData(false);
      });
    }
  }, [range]);

  return loadingData ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title="Citas agendadas"
        className="site-page-header"
      />

      <Calendar
        defaultView={defaultView}
        events={appointments}
        localizer={localizer}
        scrollToTime={moment('9', 'hh').toDate()}
        startAccessor={(appointment: Appointment) => new Date(appointment.start)}
        endAccessor={(appointment: Appointment) => new Date(appointment.end)}
        titleAccessor={(appointment: Appointment) => `${appointment.service} ${appointment.pet.name}`}
        eventPropGetter={(event: Appointment) => ({
          ...(event.clinic.id === 1 && {
            className: 'orangeEvent',
          }),
          ...(event.clinic.id === 2 && {
            className: 'blueEvent',
          }),
        })}
        onSelectEvent={(appointment: Appointment) => navigate(`/appointments/${appointment.id}`)}
        onRangeChange={(newRange) => {
          if (Array.isArray(newRange)) {
            setRange({
              start: moment(newRange[0]).startOf('day').toDate(),
              end: moment(newRange[newRange.length - 1]).endOf('day').toDate(),
            });
          } else {
            setRange({
              start: moment(newRange.start).startOf('day').toDate(),
              end: moment(newRange.end).endOf('day').toDate(),
            });
          }
        }}
        messages={messages}
        step={15}
        timeslots={2}
        style={{ height: 500 }}
      />
    </div>
  );
}
