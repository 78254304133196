import React, { useEffect } from 'react';
import './App.css';
import moment from 'moment';
import {
  Route, Routes, useNavigate,
} from 'react-router-dom';
import Home, { HomeRedirectToSignIn } from './pages/Home';
import MenuLayout from './components/MenuLayout';
import useToken from './hooks/useToken';
import useUserData, { fetchUserData } from './hooks/useUserData';
import { axiosInstance, noAuthAxios } from './utils/axios-instance';
import { Token, User } from './types/base';
import SignIn from './pages/SignIn';
import AddPet from './pages/pets/AddPet';
import Pets from './pages/pets/Pets';
import PetDetails from './pages/pets/PetDetails';
import PasswordReset from './pages/users/PasswordReset';
import Profile from './pages/users/Profile';
import RequestPasswordReset from './pages/users/RequestPasswordReset';
import VerifyUser from './pages/users/VerifyUser';
import Owners from './pages/owners/Owners';
import AddOwner from './pages/owners/AddOwner';
import OwnerDetails from './pages/owners/OwnerDetails';
import Appointments from './pages/services/Appointments';
import Transportations from './pages/services/Transportations';
import TransportationDetails from './pages/services/TransportationDetails';
import AppointmentDetails from './pages/services/AppointmentDetails';
import Orders from './pages/users/Orders';
import Loading from './components/Loading';

const localization = require('moment/locale/es');

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home
    navigate('/', { replace: true });
  });

  return (
    <Loading />
  );
}

function App() {
  const { token, setToken } = useToken();
  const { userData, setUserData } = useUserData();
  moment.updateLocale('es', localization);

  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  // Interceptor for expired access token
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 && token?.refresh) {
        const { refresh } = token;
        if (refresh) {
          const data = {
            refresh,
          };
          return noAuthAxios
            .post(
              `${process.env.REACT_APP_API_URL}/token/refresh/`,
              data,
            )
            .then((response) => {
              const newToken: Token = response.data;
              const { access } = newToken;
              // eslint-disable-next-line
              error.response.config.headers.Authorization = `Bearer ${access}`;
              axiosInstance.defaults.headers.common.Authorization = `Bearer ${access}`;
              setToken({ ...token, access });
              return axiosInstance(error.config);
            })
            .catch((err) => {
              logout();
              return Promise.reject(err);
            });
        }
        logout();
      }
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    if (token) {
      const { access } = token;
      // Update headers
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${access}`;
      // Update user permissions
      fetchUserData().then((newUserData: User) => {
        setUserData(newUserData);
      });
    }
  }, [token]);

  if (!token || !userData || !userData.permissions) {
    return (
      <div className="App">
        <MenuLayout isLoggedIn={false}>
          <Routes>
            <Route path="/" element={<HomeRedirectToSignIn redirect={!token} />} />
            <Route path="/signin" element={<SignIn setToken={setToken} />} />
            <Route path="/verify-user/:token" element={<VerifyUser setToken={setToken} />} />
            <Route path="/request-password-reset" element={<RequestPasswordReset />} />
            <Route path="/password-reset/:token" element={<PasswordReset setToken={setToken} />} />
            <Route path="/pets/:petId" element={<PetDetails />} />
          </Routes>
        </MenuLayout>
      </div>
    );
  }

  return (
    <div className="App">
      <MenuLayout userData={userData} logout={logout}>
        <Routes>
          <Route path="/" element={<Home userData={userData} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/pets/:petId" element={<PetDetails />} />
          { userData?.permissions.includes('pets.view_pet') && (
          <Route path="/pets" element={<Pets />} />
          )}
          { userData?.permissions.includes('pets.add_pet') && (
            <Route path="/pets/add" element={<AddPet />} />
          )}
          { userData?.permissions.includes('users.view_user') && userData?.permissions.includes('users.change_user') && (
            <Route path="/owners/:ownerId" element={<OwnerDetails />} />
          )}
          { userData?.permissions.includes('users.view_user') && (
            <Route path="/owners" element={<Owners />} />
          )}
          { userData?.permissions.includes('users.add_user') && (
            <Route path="/owners/add" element={<AddOwner />} />
          )}
          { userData?.permissions.includes('services.view_appointment') && (
            <Route path="/appointments" element={<Appointments />} />
          )}
          { userData?.permissions.includes('services.view_appointment') && (
            <Route path="/appointments/:appointmentId" element={<AppointmentDetails />} />
          )}
          { userData?.permissions.includes('services.view_transportation') && (
            <Route path="/transportation" element={<Transportations />} />
          )}
          { userData?.permissions.includes('services.view_transportation') && (
            <Route path="/transportation/:transportationId" element={<TransportationDetails />} />
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MenuLayout>
    </div>
  );
}

export default App;
