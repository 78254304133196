import React, { useEffect, useState } from 'react';
import {
  List, PageHeader, Space, Table, Typography,
} from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Order, Pet } from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showErrorModal } from '../../utils/showErrorModal';
import Loading from '../../components/Loading';
import {
  defaultPagination,
  defaultParams, handleTableChange,
  renderDate,
  renderNumericalValue,
} from '../../utils/tables';

const { Text } = Typography;

interface FetchOrdersParams {
  limit?: number;
  offset?: number;
}

export default function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [fetchParams, setFetchParams] = useState<FetchOrdersParams>(defaultParams);

  const fetchOrdersList = (
    newPagination: TablePaginationConfig,
    params: FetchOrdersParams = {},
  ) => new Promise(
    (resolve: (value: Pet[]) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/orders/`,
        { params },
      ).then((response) => {
        setOrders(response.data.results);
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        resolve(response.data.results);
      }).catch((e) => {
        showErrorModal();
        reject(e);
      });
    },
  );

  useEffect(() => {
    fetchOrdersList(pagination, fetchParams).then(() => setLoadingData(false));
  }, []);

  const onTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: (SorterResult<Order> | SorterResult<Order>[]),
  ) => {
    handleTableChange(
      newPagination,
      filters,
      sorter,
      (pagConfig, params) => {
        fetchOrdersList(pagConfig, params).then(() => setLoadingData(false));
      },
    );
  };

  const columns = [
    {
      title: 'Pedido',
      dataIndex: 'woocommerce_id',
      key: 'order_id',
      render: (value: number) => `#${value}`,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: renderDate,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value: number) => `$${renderNumericalValue(value)}`,
    },
  ];

  return loadingData ? (
    <Loading />
  ) : (
    <div className="site-layout-content">
      <PageHeader
        title="Mis pedidos"
        className="site-page-header"
      />
      <Table
        rowKey="id"
        dataSource={orders}
        columns={columns}
        scroll={{ x: 900 }}
        pagination={pagination}
        onChange={onTableChange}
        expandable={{
          expandedRowRender: (record: Order) => (
            <List
              dataSource={record.items}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={(
                      <Space>
                        <a href={item.url}>{item.name}</a>
                        <Text type="secondary">
                          x
                          {item.quantity}
                        </Text>
                      </Space>
                    )}
                  />
                  <div>
                    $
                    {renderNumericalValue(item.total)}
                  </div>
                </List.Item>
              )}
            />
          ),
        }}
      />
    </div>
  );
}
