import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../types/base';
import Loading from '../components/Loading';

type HomeRedirectToSignInProps = {
  redirect?: boolean;
};

export function HomeRedirectToSignIn({ redirect = true }: HomeRedirectToSignInProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (redirect) {
      navigate('/signin', { replace: true });
    }
  });

  return (
    <Loading />
  );
}

type HomeProps = {
  userData?: User;
};

export default function Home({ userData }: HomeProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      if (userData.groups.includes('carriers')) {
        navigate('/transportation', { replace: true });
      } else {
        navigate('/appointments', { replace: true });
      }
    }
  }, [userData]);

  return (
    <div className="site-layout-content">
      {userData && (
        <h2>
          {`Hola, ${userData.full_name}!`}
        </h2>
      )}
    </div>
  );
}
