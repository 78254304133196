import { RcFile } from 'rc-upload/lib/interface';
import { message, Upload } from 'antd';

const acceptedSizeMB = 25;

export function validateFileSize(file: RcFile) {
  const isAcceptedSize = file.size / 1024 / 1024 < acceptedSizeMB;
  if (!isAcceptedSize) {
    message.error(`El archivo debe pesar menos de ${acceptedSizeMB}MB`);
  }
  return isAcceptedSize ? true : Upload.LIST_IGNORE;
}

export function validateFileFormat(file: RcFile, acceptedFormats: string[]) {
  const isAcceptedFormat = acceptedFormats.includes(file.type);
  if (!isAcceptedFormat) {
    message.error('Tipo de archivo inválido');
  }
  return isAcceptedFormat ? true : Upload.LIST_IGNORE;
}
