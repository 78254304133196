import React from 'react';
import { Input } from 'antd';
import { formatRut } from '../utils/rut';

interface PriceInputProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

export function RutInput({ value = '', onChange, ...props }: PriceInputProps) {
  const onRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRut = formatRut((e.target as HTMLInputElement).value || '');
    onChange?.(newRut);
  };

  return (
    <Input
      type="text"
      value={value}
      onChange={onRutChange}
      {...props}
    />
  );
}
